import { FC, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { ResponsiveDialog } from 'src/shared/ui/responsiveDialog';
import { BasicSelectAutocomplete } from 'src/shared/ui/basicSelectAutocomplete';
import { HeaderDialog, FooterDialog, BodyDialog } from 'src/shared/ui/responsiveDialog/ui';
import { InputFileUpload } from 'src/shared/ui/inputFileUpload';
import {
  MetadataMap,
  RelationOption,
  RelationOptionsMap,
  TableManagerFieldValue,
  TagType,
  TypeNotation,
} from 'src/shared/types';
import { MuiButton } from 'src/shared/ui/muiButton';
import { useCreateDocumentsMutation } from 'src/store/api/documents';
import { showToastErrorMessage } from 'src/shared/utils';
import { DEFAULT_SIZE_MUI_SPINNER } from 'src/shared/constants';

import { addDocumentSchema } from '../../helpers/validation';

const DOCUMENT_UPLOAD_FORM_ID = 'document-upload-form';

type FileUploadDialogProps = {
  relationOptions: RelationOptionsMap;
  schemaElement: TypeNotation;
  fieldsMetadata: MetadataMap;
  modelName: TagType;
  state: Record<string, TableManagerFieldValue>;
  idField: string;
};

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '.5rem',
}));

const FileUploadDialog: FC<FileUploadDialogProps> = ({
  relationOptions,
  schemaElement,
  fieldsMetadata,
  modelName,
  state,
  idField,
}) => {
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [createDocuments, { isLoading: isCreatingDocuments }] = useCreateDocumentsMutation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const initialValues = useMemo(
    () => ({
      documentType: null,
      files: [],
    }),
    [],
  );

  const { values, handleChange, handleSubmit, errors, resetForm, isSubmitting, dirty } = useFormik({
    onSubmit: async (data: z.infer<typeof addDocumentSchema>) => {
      const formData = new FormData();

      if (data.documentType) {
        formData.append('documentTypeId', data.documentType.value);
      }

      if (modelName) {
        formData.append('modelName', modelName);
      }

      if (state?.[idField]) {
        formData.append('entityId', String(state[idField]));
      }

      data.files.forEach((file) => {
        formData.append('files', file);
      });

      try {
        await createDocuments(formData);
      } catch (error) {
        showToastErrorMessage(`There was an error trying to upload document`);
      } finally {
        resetForm();
        setIsFileDialogOpen(false);
      }
    },

    validationSchema: toFormikValidationSchema(addDocumentSchema),
    initialValues,
    enableReinitialize: true,
  });

  const handleOpenDialog = () => {
    setIsFileDialogOpen(true);
  };

  const closeDialog = () => {
    setIsFileDialogOpen(false);
    resetForm();
  };

  const handleChangeDocumentType = (value: RelationOption | null) => {
    handleChange({
      target: {
        name: 'documentType',
        value,
      },
    });
  };

  const handleFileChange = (files: File[]) => {
    handleChange({
      target: {
        name: 'files',
        value: files,
      },
    });
  };

  const key =
    fieldsMetadata?.customComponents?.[modelName]?.[schemaElement.field]?.settings
      ?.documentTypeFieldName || '';
  const items = relationOptions[key];

  const hasValidationErrors = Object.entries(errors).length > 0;

  const isSubmitDisabled = hasValidationErrors || isSubmitting || !dirty;

  if (!items) {
    return null;
  }

  return (
    <Box>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        onClick={handleOpenDialog}
      >
        Upload files
      </Button>

      {isFileDialogOpen && (
        <ResponsiveDialog
          open={isFileDialogOpen}
          onClose={closeDialog}
          id="file-dialog"
          sx={{
            maxWidth: fullScreen ? '100%' : '40rem',
            maxHeight: '30rem',
          }}
        >
          <HeaderDialog
            title="Upload files"
            onClose={closeDialog}
          />

          <BodyDialog>
            <Form
              id={DOCUMENT_UPLOAD_FORM_ID}
              onSubmit={handleSubmit}
            >
              <BasicSelectAutocomplete
                items={items}
                handleChange={handleChangeDocumentType}
                selectedItem={values.documentType}
                label="Select Document Type"
                disabled={isCreatingDocuments}
              />

              <InputFileUpload
                handleFileChange={handleFileChange}
                files={values.files}
                disabled={isCreatingDocuments}
                multiple
              />
            </Form>
          </BodyDialog>

          <FooterDialog>
            <MuiButton
              variant="contained"
              color="info"
              onClick={closeDialog}
              sx={{ minWidth: '6rem' }}
              disabled={isCreatingDocuments}
            >
              Cancel
            </MuiButton>

            <MuiButton
              type="submit"
              variant="contained"
              color="success"
              autoFocus
              sx={{ minWidth: '9rem' }}
              disabled={isSubmitDisabled || isCreatingDocuments}
              form={DOCUMENT_UPLOAD_FORM_ID}
            >
              {!isCreatingDocuments ? (
                'Upload files'
              ) : (
                <CircularProgress size={DEFAULT_SIZE_MUI_SPINNER.button} />
              )}
            </MuiButton>
          </FooterDialog>
        </ResponsiveDialog>
      )}
    </Box>
  );
};

export { FileUploadDialog };
