import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Button } from 'src/shared/ui/button';
import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/outlined/chevrons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/outlined/chevrons/chevron-up.svg';
import { ReactComponent as ChevronLeftIcon } from 'src/assets/icons/filled/chevrons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'src/assets/icons/filled/chevrons/chevron-right.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { useAppDispatch } from 'src/store';
import { calendarActions } from 'src/store/slices';

import { CALENDAR_SIZE, DATE_FORMAT } from '../../constants';
import { DatepickerContext } from '../../contexts/DatepickerContext';
import {
  formatDate,
  getDaysInMonth,
  getFirstDayInMonth,
  getFirstDaysInMonth,
  getLastDaysInMonth,
  getNumberOfDay,
  loadLanguageModule,
  nextMonth,
  previousMonth,
} from '../../helpers';
import { DateType } from '../../types';

import { Days } from './Days';
import { Months } from './Months';
import { Week } from './Week';
import { Years } from './Years';

interface Props {
  date: dayjs.Dayjs;
  minDate?: DateType | null;
  maxDate?: DateType | null;
  onClickPrevious: () => void;
  onClickNext: () => void;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
}

const Calendar: React.FC<Props> = ({
  date,
  minDate,
  maxDate,
  onClickPrevious,
  onClickNext,
  changeMonth,
  changeYear,
}) => {
  // Contexts
  const {
    period,
    changePeriod,
    changeDayHover,
    showFooter,
    changeDatepickerValue,
    hideDatepicker,
    asSingle,
    asWeek,
    as3Days,
    asCustom,
    i18n,
    startWeekOn,
    input,
  } = useContext(DatepickerContext);
  loadLanguageModule(i18n);

  const dispatch = useAppDispatch();

  // States
  const [showMonths, setShowMonths] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [year, setYear] = useState(date.year());
  // Functions
  const previous = useCallback(() => {
    return getLastDaysInMonth(
      previousMonth(date),
      getNumberOfDay(getFirstDayInMonth(date).ddd, startWeekOn),
    );
  }, [date, startWeekOn]);

  const current = useCallback(() => {
    return getDaysInMonth(formatDate(date));
  }, [date]);

  const next = useCallback(() => {
    return getFirstDaysInMonth(
      previousMonth(date),
      CALENDAR_SIZE - (previous().length + current().length),
    );
  }, [current, date, previous]);

  const hideMonths = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    showMonths && setShowMonths(false);
  }, [showMonths]);

  const hideYears = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    showYears && setShowYears(false);
  }, [showYears]);

  const clickMonth = useCallback(
    (month: number) => {
      setTimeout(() => {
        changeMonth(month);
        hideMonths();
      }, 250);
    },
    [changeMonth, hideMonths],
  );

  const clickYear = useCallback(
    (year: number) => {
      setTimeout(() => {
        changeYear(year);
        setShowMonths(!showMonths);
        hideYears();
      }, 250);
    },
    [changeYear, hideYears, showMonths],
  );

  const clickDay = useCallback(
    (day: number, month = date.month() + 1, year = date.year()) => {
      const fullDay = `${year}-${month}-${day}`;
      let newStart;
      let newEnd = null;

      function choosePeriod(start: string, end: string) {
        const ipt = input?.current;
        changeDatepickerValue(
          {
            startDate: dayjs(start).format(DATE_FORMAT),
            endDate: dayjs(end).format(DATE_FORMAT),
          },
          ipt,
        );
        hideDatepicker();
      }

      if (asWeek && !as3Days) {
        const selectedDate = dayjs(fullDay);
        const isSunday = selectedDate.day() === 0;

        const formattedDate = isSunday ? selectedDate.subtract(2, 'day') : selectedDate;

        const firstDayOfWeek = String(formattedDate.startOf('week').add(1, 'day'));
        const lastDayOfWeek = String(formattedDate.endOf('week').add(1, 'day'));

        choosePeriod(firstDayOfWeek, lastDayOfWeek);
        dispatch(calendarActions.changeSelectedDate({ date: firstDayOfWeek }));

        return;
      }

      if (as3Days) {
        const selectedDate = dayjs(fullDay);
        const firstDate = String(selectedDate);
        const lastDay = String(selectedDate.add(3, 'day'));

        choosePeriod(firstDate, lastDay);
        dispatch(calendarActions.changeSelectedDate({ date: firstDate }));
      }

      if (period.start && period.end) {
        if (changeDayHover) {
          changeDayHover(null);
        }
        changePeriod({
          start: null,
          end: null,
        });
      }

      if ((!period.start && !period.end) || (period.start && period.end)) {
        if (!period.start && !period.end) {
          changeDayHover(fullDay);
        }
        newStart = fullDay;
        if (asSingle) {
          newEnd = fullDay;
          choosePeriod(fullDay, fullDay);
        }
        if (asCustom) {
          changeDatepickerValue(
            {
              startDate: null,
              endDate: null,
            },
            input?.current,
          );
        }
      } else {
        if (period.start && !period.end) {
          // start not null
          // end null
          const condition =
            dayjs(fullDay).isSame(dayjs(period.start)) ||
            dayjs(fullDay).isAfter(dayjs(period.start));
          newStart = condition ? period.start : fullDay;
          newEnd = condition ? fullDay : period.start;
        } else {
          // Start null
          // End not null
          const condition =
            dayjs(fullDay).isSame(dayjs(period.end)) || dayjs(fullDay).isBefore(dayjs(period.end));
          newStart = condition ? fullDay : period.start;
          newEnd = condition ? period.end : fullDay;
        }

        if (!showFooter) {
          if (newStart && newEnd) {
            choosePeriod(newStart, newEnd);
          }
        }
      }

      if (!(newEnd && newStart) || showFooter) {
        changePeriod({
          start: newStart,
          end: newEnd,
        });
      }
    },
    [
      asSingle,
      asWeek,
      changeDatepickerValue,
      changeDayHover,
      changePeriod,
      date,
      hideDatepicker,
      period.end,
      period.start,
      showFooter,
      input,
      asCustom,
    ],
  );

  const clickPreviousDays = useCallback(
    (day: number) => {
      const newDate = previousMonth(date);
      clickDay(day, newDate.month() + 1, newDate.year());
      onClickPrevious();
    },
    [clickDay, date, onClickPrevious],
  );

  const clickNextDays = useCallback(
    (day: number) => {
      const newDate = nextMonth(date);
      clickDay(day, newDate.month() + 1, newDate.year());
      onClickNext();
    },
    [clickDay, date, onClickNext],
  );

  // UseEffects & UseLayoutEffect
  useEffect(() => {
    setYear(date.year());
  }, [date]);

  // Variables
  const calendarData = useMemo(() => {
    return {
      date,
      days: {
        previous: previous(),
        current: current(),
        next: next(),
      },
    };
  }, [current, date, next, previous]);
  const minYear = React.useMemo(
    () => (minDate && dayjs(minDate).isValid() ? dayjs(minDate).year() : null),
    [minDate],
  );
  const maxYear = React.useMemo(
    () => (maxDate && dayjs(maxDate).isValid() ? dayjs(maxDate).year() : null),
    [maxDate],
  );

  return (
    <div className="min-w-[364px]">
      <div className="flex items-center pl-[16px] pr-[16px] py-[8px]">
        <div className="flex flex-1 items-center justify-between">
          <Button
            variant="ghost"
            className="text-textColor-hint"
            onClick={() => {
              setShowYears(!showYears);
              if (showMonths) hideMonths();
            }}
            endIcon={showYears || showMonths ? <ChevronUpIcon /> : <ChevronDownIcon />}
            iconClassName="fill-textColor-tertiary"
          >
            <>{calendarData.date.locale(i18n).format('MMMM YYYY')}</>
          </Button>

          <div className="flex gap-x-2 pr-2">
            <IconButton
              color="basic"
              size="none"
              iconClassName="fill-textColor-tertiary"
              onClick={onClickPrevious}
            >
              <ChevronLeftIcon />
            </IconButton>

            <IconButton
              color="basic"
              size="none"
              iconClassName="fill-textColor-tertiary"
              onClick={() => {
                setYear(year + 12);
                onClickNext();
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <div>
        {showMonths && (
          <Months
            currentMonth={calendarData.date.month() + 1}
            clickMonth={clickMonth}
          />
        )}

        {showYears && (
          <Years
            year={year}
            minYear={minYear}
            maxYear={maxYear}
            currentYear={calendarData.date.year()}
            clickYear={clickYear}
          />
        )}

        {!showMonths && !showYears && (
          <>
            <Week />

            <Days
              calendarData={calendarData}
              onClickPreviousDays={clickPreviousDays}
              onClickDay={clickDay}
              onClickNextDays={clickNextDays}
            />
          </>
        )}
      </div>
    </div>
  );
};

export { Calendar };
