import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { GridCallbackDetails, GridSortModel } from '@mui/x-data-grid';

import {
  TagType,
  AdminPageContextType,
  PaginationModelAdminPage,
  TableManagerFieldValue,
  SortOption,
  SortOrder,
  Subjects,
} from 'src/shared/types';
import { useDebounce, usePermissions } from 'src/shared/hooks';
import { useGetTableDataQuery, useGetRelationOptionsQuery } from 'src/store/api';
import { selectTableState, TypeAdminPageModal } from 'src/store/slices';
import { TypographyAccessDenied } from 'src/shared/ui/muiTypography';

import { CreateOrUpdateTableRowModal } from '../CreateOrUpdateTableRowModal';
import { CustomDataGrid } from '../CustomDataGrid';
import { ToolbarDataGrid } from '../ToolbarDataGrid';
import { DetailsTableRowModal } from '../DetailsTableRowModal';

type DataGridContainerProps = {
  modelName: TagType;
  modelLabel?: string;
};

const SEARCH_FIELD_DELAY = 500;

const DataGridContainer: FC<DataGridContainerProps> = ({ modelName, modelLabel }) => {
  const { can } = usePermissions(modelName as Subjects);
  const { modelsOptions, isFetchingModelsOptions } = useOutletContext<AdminPageContextType>();
  const [isCreateOrUpdateTableRowModalOpen, setIsCreateOrUpdateTableRowModalOpen] = useState(false);
  const [isDetailsTableRowModalOpen, setIsDetailsTableRowModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Record<string, TableManagerFieldValue> | null>(
    null,
  );

  const { selectedRowId, typeModal } = useSelector(selectTableState);

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchField, setSearchField] = useState<string>(searchParams.get('search') || '');
  const [orderBy, setOrderBy] = useState<SortOption | undefined>();

  const debouncedSearchField = useDebounce(searchField, SEARCH_FIELD_DELAY);

  const timerId = useRef<NodeJS.Timeout | null>(null);

  const [paginationModel, setPaginationModel] = useState<PaginationModelAdminPage>({
    pageSize: parseInt(searchParams.get('pageSize') || '10', 10),
    page: parseInt(searchParams.get('page') || '0', 10),
  });

  const [relationOptionsFilters, setRelationOptionsFilters] = useState<
    Record<string, Record<string, string>>
  >({});

  const {
    data = {
      data: [],
      schema: [],
      idField: '',
      total: 0,
      totalPages: 0,
      foreignKeys: [],
      fieldsMetadata: {},
    },
    isLoading,
    isFetching,
  } = useGetTableDataQuery(
    {
      apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
      modelName,

      pagination: {
        pageSize: String(paginationModel.pageSize),
        page: String(paginationModel.page + 1),
      },
      filters: {
        search: debouncedSearchField.trim(),
      },
      sort: orderBy,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !can.read,
    },
  );

  const {
    data: tableData,
    schema,
    idField,
    total,
    foreignKeys = [],
    fieldsMetadata = {},
    rowsMetadata,
  } = data;

  const {
    data: relationOptions = {},
    isLoading: isLoadingRelationOptions,
    isFetching: isFetchingRelationOptions,
  } = useGetRelationOptionsQuery(
    {
      models: foreignKeys,
      filters: relationOptionsFilters,
      invalidatesTags: fieldsMetadata?.invalidatesTags,
    },
    {
      skip: foreignKeys.length === 0 || !can.read,
    },
  );

  const handleSearchFieldChange = (searchFieldValue: string) => {
    setSearchField(searchFieldValue);

    if (searchField && paginationModel.page !== 0) {
      setPaginationModel((prev) => ({
        ...prev,
        page: 0,
      }));

      setSearchParams({
        page: String(0),
        pageSize: paginationModel.pageSize.toString(),
        search: searchField,
      });
    }
  };

  const handleDetailsModal = useCallback(
    (id: string | number | null) => {
      const selectedRow = tableData.find((el) => el[idField] === id);

      if (selectedRow) {
        setSelectedRow(selectedRow);

        setIsDetailsTableRowModalOpen(true);
      }
    },
    [idField, tableData],
  );

  const handleUpdateModal = useCallback(
    (id: string | number | null) => {
      const selectedRow = tableData.find((el) => el[idField] === id);

      if (selectedRow) {
        setSelectedRow(selectedRow);

        setIsCreateOrUpdateTableRowModalOpen(true);
      }
    },
    [idField, tableData],
  );

  useEffect(() => {
    if (selectedRowId && typeModal === TypeAdminPageModal.Details) {
      handleDetailsModal(selectedRowId);
    }
    if (selectedRowId && typeModal === TypeAdminPageModal.Create) {
      setIsCreateOrUpdateTableRowModalOpen(true);
    }

    if (selectedRowId && typeModal === TypeAdminPageModal.Update) {
      handleUpdateModal(selectedRowId);
    }
  }, [selectedRowId, typeModal, handleDetailsModal, handleUpdateModal]);

  useEffect(() => {
    if (timerId?.current) {
      clearTimeout(timerId.current);
    }

    timerId.current = setTimeout(() => {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      if (!debouncedSearchField) {
        newSearchParams.delete('search');

        setSearchParams(newSearchParams);
      } else {
        newSearchParams.set('search', searchField);
      }
      setSearchParams(newSearchParams);
    }, SEARCH_FIELD_DELAY);

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchField]);

  const onColumnOrderChange = (model: GridSortModel, details: GridCallbackDetails) => {
    const { field, sort } = model[0] || {};
    if (field && sort) {
      setOrderBy({
        fieldKey: field,
        order: sort as SortOrder,
      });
    }
  };

  const isLoadingOverall = isLoading || isLoadingRelationOptions || isFetchingModelsOptions;

  const canCreateOrUpdate = can.create || can.update;

  return (
    <>
      {isLoadingOverall && (
        <div className="w-full h-full flex justify-center items-center">
          <CircularProgress />
        </div>
      )}

      {can.read ? (
        <div className="flex flex-col relative rounded-md ">
          <ToolbarDataGrid
            modelName={modelName}
            modelLabel={modelLabel}
            setIsOpen={setIsCreateOrUpdateTableRowModalOpen}
            searchField={searchField}
            isFetching={isFetching}
            handleChange={handleSearchFieldChange}
            fieldsMetadata={fieldsMetadata}
          />

          <CustomDataGrid
            modelName={modelName}
            schema={schema}
            tableData={tableData}
            isLoading={isLoading}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            idField={idField}
            total={total}
            relationOptions={relationOptions}
            isFetching={isFetching}
            isLoadingRelationOptions={isLoadingRelationOptions}
            isFetchingRelationOptions={isFetchingRelationOptions}
            setSelectedRow={setSelectedRow}
            setIsCreateOrUpdateTableRowModalOpen={setIsCreateOrUpdateTableRowModalOpen}
            fieldsMetadata={fieldsMetadata}
            rowsMetadata={rowsMetadata}
            onColumnOrderChange={onColumnOrderChange}
          />

          {canCreateOrUpdate && isCreateOrUpdateTableRowModalOpen && (
            <CreateOrUpdateTableRowModal
              schema={schema}
              idField={idField}
              isOpen={isCreateOrUpdateTableRowModalOpen}
              setIsOpen={setIsCreateOrUpdateTableRowModalOpen}
              modelName={modelName}
              modelLabel={modelLabel}
              relationOptions={relationOptions}
              setRelationOptionsFilters={setRelationOptionsFilters}
              foreignKeys={foreignKeys}
              fieldsMetadata={fieldsMetadata}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              isLoadingOrFetchingRelationOptions={
                isLoadingRelationOptions || isFetchingRelationOptions
              }
            />
          )}

          {isDetailsTableRowModalOpen && (
            <DetailsTableRowModal
              schema={schema}
              isOpen={isDetailsTableRowModalOpen}
              setIsOpen={setIsDetailsTableRowModalOpen}
              modelName={modelName}
              modelLabel={modelLabel}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              relationOptions={relationOptions}
              fieldsMetadata={fieldsMetadata}
              idField={idField}
            />
          )}
        </div>
      ) : (
        <TypographyAccessDenied />
      )}
    </>
  );
};

export { DataGridContainer };
