import { FC, useMemo } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import { Modal } from 'src/shared/ui/modal';
import { Icons } from 'src/assets/icons';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { TextField } from 'src/shared/ui/textField';
import { SelectInput } from 'src/shared/ui/selectInput';
import { SelectInputItem } from 'src/shared/ui/selectInput/ui/selectInputItem';
import {
  useCreateOwnerPersonnelMutation,
  useGetOwnerPersonnelByMailQuery,
} from 'src/store/api/owner-personnel';
import { dayjs, showToastErrorMessage } from 'src/shared/utils';
import { selectCurrentUser } from 'src/store/slices';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { SEARCH_FIELD_DEBOUNCE_DELAY } from 'src/pages/actions/helpers/constants';

import { initialContactValues, PERSON_TITLES } from './constants';
import { ownerPersonnelSchema } from './validation';

interface AddNewContactProps {
  isAddNewContactModalOpen: boolean;
  toggleContactModal: () => void;
  ownerID: string;
  onCreate: () => void;
}

const AddNewContact: FC<AddNewContactProps> = ({
  isAddNewContactModalOpen,
  toggleContactModal,
  ownerID,
  onCreate,
}) => {
  const [createOwnerPersonnel, { isLoading }] = useCreateOwnerPersonnelMutation();

  const user = useSelector(selectCurrentUser);

  const { values, handleChange, handleSubmit, errors, setFieldValue } = useFormik({
    onSubmit: async (data: z.infer<typeof ownerPersonnelSchema>) => {
      if (!user) {
        return;
      }

      try {
        const nowTime = dayjs.utc().toISOString();

        await createOwnerPersonnel({
          FirstName: data.firstName,
          LastName: data.lastName,
          OwnerID: ownerID,
          OwnerPersonnelEmail: data.email,
          OwnerPersonnelPhone: data.phone || undefined,
          Title: data.title || undefined,
          CreatedBy: user.email,
          CreateDate: nowTime,
          ModifiedBy: user.email,
          ModifiedDate: nowTime,
        });
        toggleContactModal();
        onCreate();
      } catch (error) {
        showToastErrorMessage('Error with creating new contact');
      }
    },
    validationSchema: toFormikValidationSchema(ownerPersonnelSchema),
    initialValues: initialContactValues,
    enableReinitialize: true,
  });

  const debouncedSearchField = useDebounce(values.email, SEARCH_FIELD_DEBOUNCE_DELAY);

  const { data: usersByMail } = useGetOwnerPersonnelByMailQuery(
    {
      email: debouncedSearchField,
    },
    {
      skip: !!errors.email || !debouncedSearchField,
      refetchOnMountOrArgChange: true,
    },
  );

  const errorMessage = useMemo(() => {
    if (errors.email) {
      return errors.email;
    }

    if (usersByMail?.length) {
      return 'This email is already exist';
    }

    return undefined;
  }, [errors, usersByMail]);

  return (
    <Modal
      isOpen={isAddNewContactModalOpen}
      toggleModal={toggleContactModal}
      removeScroll
    >
      <Typography
        variant="h2"
        fontWeight="bold"
      >
        Add new Contact
      </Typography>

      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-2 my-5">
          <SelectInput
            name="title"
            label="Title"
            clearFieldState={() => setFieldValue('title', '')}
            value={values.title}
            placeholder="Title..."
            items={PERSON_TITLES.map((value) => ({
              label: <SelectInputItem selected={values.title === value}>{value}</SelectInputItem>,
              value,
              onClick: () => {
                handleChange({
                  target: {
                    name: 'title',
                    value,
                  },
                });
              },
            }))}
          />

          <TextField
            name="firstName"
            label="First Name"
            placeholder="First Name..."
            required
            isRequired
            value={values.firstName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'firstName',
                  value: value.target.value,
                },
              })
            }
            error={errors.firstName}
          />

          <TextField
            name="lastName"
            label="Last Name"
            placeholder="Last Name..."
            required
            isRequired
            value={values.lastName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'lastName',
                  value: value.target.value,
                },
              })
            }
            error={errors.lastName}
          />

          <TextField
            name="email"
            label="Email"
            placeholder="Email..."
            required
            isRequired
            value={values.email}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'email',
                  value: value.target.value,
                },
              })
            }
            error={errorMessage}
          />

          <TextField
            name="phone"
            label="Phone Number"
            placeholder="Phone Number..."
            value={values.phone}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'phone',
                  value: value.target.value,
                },
              })
            }
          />
        </div>

        <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={toggleContactModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            size="lg"
            endIcon={<Icons.Outlined.Edit.CheckmarkIcon />}
            autoFocus
            disabled={isLoading}
            iconClassName="pointer-events-none"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { AddNewContact };
