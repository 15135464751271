import { FC, Dispatch, SetStateAction } from 'react';

import { MetadataMap, Subjects, TagType } from 'src/shared/types';
import { usePermissions } from 'src/shared/hooks';

import { CreateToolbar } from '../CreateToolbar';
import { FilterPanel } from '../FilterPanel';
import { MapView } from '../MapView';

interface IToolbarDataGridProps {
  modelName: TagType;
  modelLabel?: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  searchField: string;
  isFetching: boolean;
  handleChange: (value: string) => void;
  fieldsMetadata: MetadataMap;
}

const ToolbarDataGrid: FC<IToolbarDataGridProps> = (props) => {
  const { fieldsMetadata, modelName } = props;
  const { can } = usePermissions(modelName as Subjects);

  return (
    <div className="flex gap-2 justify-between flex-wrap bg-white w-full py-2 px-4 items-center min-h-16">
      {can.create && (
        <CreateToolbar
          disabled={fieldsMetadata.toolbar?.isCreateButtonHidden}
          {...props}
        />
      )}

      <div className="flex gap-2 justify-end flex-1 items-center flex-wrap">
        {fieldsMetadata?.toolbar?.showSearch && <FilterPanel {...props} />}

        {fieldsMetadata?.toolbar?.showMapBtn && <MapView />}
      </div>
    </div>
  );
};

export { ToolbarDataGrid };
