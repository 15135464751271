import { api } from 'src/store/api/api';
import { SurveyEntity, SurveyEntityWithAnswers, SurveyContent } from 'src/shared/types';
import { store } from 'src/store';

export type CreateSurvey = {
  name: string;
  content: any;
  id?: string;
};

type SurveyContentGetParams = {
  id: string;
  normalizedForAnswersPage?: boolean;
};

type BasicSurveyEntity = Omit<SurveyEntity, 'answers'>;

const surveyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query<SurveyEntityWithAnswers[], ''>({
      query: () => ({
        url: '/surveys',
        method: 'GET',
      }),
      providesTags: ['Survey'],
      transformResponse: (response: { data: SurveyEntityWithAnswers[] }) => response.data,
    }),

    getSurvey: builder.query<BasicSurveyEntity, string>({
      query: (id) => ({
        url: `/surveys/${id}`,
        method: 'GET',
      }),
      providesTags: ['Survey'],
      transformResponse: (response: { data: BasicSurveyEntity }) => response.data,
    }),

    getSurveyContent: builder.query<SurveyContent, SurveyContentGetParams>({
      query: ({ id, ...params }) => ({
        url: `/surveys/${id}/content`,
        method: 'GET',
        params,
      }),
      providesTags: ['Survey'],
      transformResponse: (response: { data: SurveyContent }) => response.data,
    }),

    getPublicSurvey: builder.query<SurveyEntityWithAnswers, string>({
      query: (id) => ({
        url: `/public/surveys/${id}`,
        method: 'GET',
      }),
      providesTags: ['Survey'],
      transformResponse: (response: { data: SurveyEntityWithAnswers }) => response.data,
    }),

    createSurvey: builder.mutation<SurveyEntityWithAnswers, CreateSurvey>({
      query: (body) => ({
        url: '/surveys',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Survey'],
    }),
    copySurvey: builder.mutation<SurveyEntityWithAnswers, { surveyId: string }>({
      query: (body) => ({
        url: '/surveys/copy',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Survey'],
    }),
    editSurvey: builder.mutation<SurveyEntityWithAnswers, CreateSurvey>({
      query: ({ id, ...body }) => ({
        url: `/surveys/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(survey, { queryFulfilled, dispatch }) {
        const { user } = store.getState().auth;

        dispatch(
          surveyApi.util.updateQueryData('getSurveys', '', (surveys) => {
            if (user) {
              surveys.splice(
                surveys.findIndex((el) => el.id === survey.id),
                1,
                {
                  ...survey,
                  author: {
                    id: user.id,
                  },
                } as SurveyEntityWithAnswers,
              );
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(surveyApi.util.invalidateTags(['Survey']));
        }
      },
    }),
    deleteSurvey: builder.mutation<SurveyEntityWithAnswers, string>({
      query: (id) => ({
        url: `/surveys/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { queryFulfilled, dispatch }) {
        dispatch(
          surveyApi.util.updateQueryData('getSurveys', '', (surveys) => {
            const surveyIndex = surveys.findIndex((el) => el.id === id);
            surveys.splice(surveyIndex, 1);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(api.util.invalidateTags(['Survey']));
        }
      },
    }),
  }),
});

export const {
  useGetSurveysQuery,
  useGetSurveyContentQuery,
  useGetPublicSurveyQuery,
  useLazyGetSurveysQuery,
  useGetSurveyQuery,
  useCreateSurveyMutation,
  useCopySurveyMutation,
  useDeleteSurveyMutation,
  useEditSurveyMutation,
} = surveyApi;
