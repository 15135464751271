import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';
import clsx from 'clsx';

import { Checkbox } from 'src/shared/ui/checkbox';
import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { IconButton } from 'src/shared/ui/iconButton';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { Typography } from 'src/shared/ui/typography';
import {
  useGetActionReceiversByOwnerLocationIdQuery,
  useGetOwnerLocationsQuery,
  usePostActionReceiverByOwnerLocationIdMutation,
} from 'src/store/api';
import { Table } from 'src/shared/ui/table';
import { TextField } from 'src/shared/ui/textField';
import { showToastErrorMessage } from 'src/shared/utils';
import { ActionReceiver } from 'src/shared/types/actions';
import { PageSize, SortOrder } from 'src/shared/types';

import { DEFAULT_SELECT_VALUE } from '../activities/helpers';

import { addNewEmailReceiver } from './helpers/validation';
import { ChangeReceiverInfoModal } from './ui';
import { headersActionReceivers, initialActionReceiversValues } from './helpers/constants';

const UnclosedFindingsSettings: FC = () => {
  const [selectedOwnerLocation, setSelectedOwnerLocation] = useState(DEFAULT_SELECT_VALUE);
  const [selectedUser, setSelectedUser] = useState<ActionReceiver | null>(null);
  const [isAddNewRecipientsOpen, setIsAddNewRecipientsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<PageSize>(15);

  const [sortSettings, setSortSettings] = useState<{
    key: keyof ActionReceiver | null;
    order: SortOrder;
  }>({
    key: null,
    order: SortOrder.ASC,
  });

  const handleChangeSortSettings = (newSort: {
    key: keyof ActionReceiver | null;
    order: SortOrder;
  }) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    setSortSettings(newSort);
  };

  const handlePageSizeChange = (size: PageSize) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    setPageSize(size);
  };

  const navigate = useNavigate();

  const { data, isLoading } = useGetOwnerLocationsQuery('');

  const {
    data: actionReceiversList,
    isFetching,
    isLoading: isListLoading,
    isError,
    refetch,
  } = useGetActionReceiversByOwnerLocationIdQuery(
    {
      id: selectedOwnerLocation.id,
      pagination: {
        page: `${currentPage}`,
        pageSize: `${pageSize}`,
      },
      sorting: sortSettings,
    },
    {
      skip: !selectedOwnerLocation.id,
      refetchOnMountOrArgChange: true,
    },
  );

  const [postActionReceiver, { isLoading: isLoadingAddReceiver }] =
    usePostActionReceiverByOwnerLocationIdMutation();

  const ownerLocationsVariants = useMemo(() => {
    return (
      data?.map((location) => ({
        id: location.OwnerLocationID,
        name: location.OwnerLocation,
      })) || []
    );
  }, [data]);

  const tableData = useMemo(() => {
    return (
      actionReceiversList?.recipients.map(
        ({ id, email, ownerLocationID, firstName, isActive, lastName }) => ({
          id,
          email,
          ownerLocationID,
          firstName: firstName || '',
          isActive: isActive || false,
          lastName: lastName || '',
        }),
      ) || []
    );
  }, [actionReceiversList]);

  const totalReceivers = useMemo(() => actionReceiversList?.total || 0, [actionReceiversList]);

  const { values, handleChange, handleSubmit, resetForm, errors, touched, handleBlur } = useFormik({
    onSubmit: async (data: z.infer<typeof addNewEmailReceiver>) => {
      if (!selectedOwnerLocation.id) {
        return;
      }

      try {
        await postActionReceiver({
          email: data.email,
          ownerLocationID: selectedOwnerLocation.id,
          firstName: data.firstName,
          lastName: data.lastName,
          isActive: data.isActive,
        });

        refetch();

        resetForm();
      } catch (error: any) {
        let message = '';

        if (error?.data?.message && typeof error?.data?.message === 'string') {
          message = error.data.message;
        }

        const errorText = `Sorry, an error occurred, when you tried to add new receiver. ${message}`;

        showToastErrorMessage(errorText);
      }
    },
    validationSchema: toFormikValidationSchema(addNewEmailReceiver),
    initialValues: initialActionReceiversValues,
    enableReinitialize: true,
  });

  const duplicateError = useMemo(() => {
    if (errors.email) {
      return errors.email;
    }
    const isInList = tableData.some((info) => info.email === values.email);

    return isInList ? 'This email is already in list' : undefined;
  }, [tableData, values.email, errors.email]);

  const isButtonDisabled = useMemo(
    () =>
      !!duplicateError ||
      !values.firstName ||
      !values.lastName ||
      isLoadingAddReceiver ||
      !selectedOwnerLocation.id,
    [
      duplicateError,
      values.firstName,
      values.lastName,
      isLoadingAddReceiver,
      selectedOwnerLocation,
    ],
  );

  return (
    <div className="w-full py-3 px-6">
      <div className="flex items-start gap-2">
        <IconButton
          onClick={() => navigate('/actions/unclosed-findings')}
          className="w-fit"
        >
          <Icons.Filled.Chevrons.ChevronLeft />
        </IconButton>

        <Typography
          variant="h2"
          className="flex-1 text-center"
        >
          Unclosed Findings Settings
        </Typography>
      </div>

      <div className="bg-white rounded-xl p-3 my-5">
        <div className="flex flex-col lg:flex-row gap-5">
          <div className="w-full lg:w-1/2">
            <SelectInput
              isRequired
              required
              label="Select Refinery"
              value={selectedOwnerLocation.name}
              placeholder="Select Refinery ..."
              disabled={isLoading}
              onClear={() => setSelectedOwnerLocation(DEFAULT_SELECT_VALUE)}
              items={
                ownerLocationsVariants.map(({ id, name }) => ({
                  label: (
                    <SelectInputItem selected={selectedOwnerLocation.name === name}>
                      {name}
                    </SelectInputItem>
                  ),
                  value: id,
                  onClick: () => {
                    setSelectedOwnerLocation({
                      id,
                      name,
                    });
                  },
                })) ?? []
              }
            />
          </div>

          {!!selectedOwnerLocation.id && (
            <div className="flex items-end lg:justify-end w-full lg:w-1/2">
              <Button
                variant="outlined"
                size="lg"
                endIcon={<Icons.Filled.Chevrons.ChevronLeft />}
                iconClassName={clsx(
                  isAddNewRecipientsOpen ? 'rotate-90 fill-semanticColor-success' : '-rotate-90',
                )}
                autoFocus
                className={clsx(
                  'w-max',
                  isAddNewRecipientsOpen &&
                    '!border-semanticColor-success !text-semanticColor-success',
                )}
                onClick={() => setIsAddNewRecipientsOpen((prev) => !prev)}
              >
                Add New Recipients
              </Button>
            </div>
          )}
        </div>

        {isAddNewRecipientsOpen && (
          <form
            className="w-full my-10 flex flex-col gap-5"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col lg:flex-row gap-5">
              <TextField
                label="First Name"
                value={values.firstName}
                name="firstName"
                required
                isRequired
                placeholder="Add first name..."
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: 'firstName',
                      value: value.target.value,
                    },
                  })
                }
                onBlur={handleBlur('firstName')}
                error={touched.firstName ? errors.firstName : undefined}
              />

              <TextField
                label="Last Name"
                name="lastName"
                value={values.lastName}
                required
                isRequired
                placeholder="Add last name..."
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: 'lastName',
                      value: value.target.value,
                    },
                  })
                }
                onBlur={handleBlur('lastName')}
                error={touched.lastName ? errors.lastName : undefined}
              />
            </div>

            <TextField
              label="Email"
              name="email"
              value={values.email}
              placeholder="Add email..."
              required
              isRequired
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'email',
                    value: value.target.value,
                  },
                })
              }
              onBlur={handleBlur('email')}
              error={touched.email ? duplicateError : undefined}
            />

            <Checkbox
              name="isActive"
              checked={values.isActive}
              endLabel="Is User Active"
              onChange={handleChange}
              labelClassName="text-textColor-secondary text-[16px] heading-[16px] font-semibold"
            />

            <Button
              type="submit"
              color="primary"
              size="lg"
              endIcon={<Icons.Outlined.Edit.CheckmarkIcon className="fill-white" />}
              autoFocus
              className="w-max"
              disabled={isButtonDisabled}
            >
              Add Recipient
            </Button>
          </form>
        )}

        {!!selectedOwnerLocation.id && (
          <div className="my-5">
            <Typography
              variant="h3"
              className="flex-1 text-center my-5"
            >
              Recipients List
            </Typography>

            <Table
              headers={headersActionReceivers}
              data={tableData}
              isFetching={isFetching}
              isLoading={isListLoading}
              sortSettings={sortSettings}
              onSort={handleChangeSortSettings}
              withIndicator
              variant="secondary"
              containerClassName="h-full"
              sticky
              scroll
              isError={isError}
              pagination={{
                currentPage,
                onPageChange: (page: number) => setCurrentPage(page),
                onPageSizeChange: handlePageSizeChange,
                pageSize,
                total: totalReceivers,
              }}
              onRowClick={(e, item) => setSelectedUser(item)}
            />
          </div>
        )}
      </div>

      {selectedUser && (
        <ChangeReceiverInfoModal
          isOpen={!!selectedUser}
          closeModal={() => setSelectedUser(null)}
          onChangeInfo={refetch}
          receiverInfo={selectedUser}
        />
      )}
    </div>
  );
};

export { UnclosedFindingsSettings };
