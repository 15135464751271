import { Chevrons } from './chevrons';
import { Edit } from './edit';
import { Misc } from './misc';
import { Menus } from './menus';
import { InternetCode } from './internet&code';
import { Files } from './files';
import { Security } from './security';

export const Filled = {
  Edit,
  Chevrons,
  Misc,
  Menus,
  InternetCode,
  Files,
  Security,
};
