import { Theme } from '@mui/material/styles';

import { ColorsType } from './constants';

const generateReadOnlyStyles = (colors: ColorsType, theme: Theme) => ({
  'color': colors.readOnly.textColor,
  'backgroundColor': colors.readOnly.backgroundColor,

  '& > .MuiOutlinedInput-root': {
    color: colors.readOnly.textColor,
  },
  '& > .MuiInputBase-root.Mui-focused': {
    color: theme.palette.common.black,
  },
});

export { generateReadOnlyStyles };
