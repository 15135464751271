import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as CalendarIcon } from 'src/assets/icons/outlined/misc/calendar.svg';
import { Icons } from 'src/assets/icons';
import { API_URL } from 'src/config';
import { Icon } from 'src/shared/ui/icon';
import { Button } from 'src/shared/ui/button';
import { User, authActions, selectCurrentUser } from 'src/store/slices';
import { getTenant } from 'src/shared/utils/tenant';
import { useGetPortalProviderSettingsQuery, useIsB2CAuthEnabledQuery } from 'src/store/api';
import { Spinner } from 'src/shared/ui/spinner';
import { normalizeUrl } from 'src/shared/utils';
import { BtnSkeleton } from 'src/shared/ui/skeleton/ui/btnSkeleton';

import { AUTH_BTN_SKELETON_COUNT } from './helpers/constants';

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = authActions;
  const [searchParams] = useSearchParams();
  const user = useSelector(selectCurrentUser);
  const token = searchParams.get('token');

  const { data: configuration, isLoading: isConfigLoading } = useGetPortalProviderSettingsQuery(
    {},
    {
      skip: !user?.accessToken,
    },
  );
  const { data: b2cAuth, isLoading: isLoadingB2CAuth } = useIsB2CAuthEnabledQuery();

  const parsedUser = useMemo(
    () =>
      token
        ? ({
            ...jwtDecode(token),
            accessToken: token,
          } as User)
        : null,
    [token],
  );

  useEffect(() => {
    if (parsedUser) {
      dispatch(login(parsedUser));
    }
  }, [dispatch, login, parsedUser, token]);

  useEffect(() => {
    if (user?.accessToken && !isConfigLoading) {
      const redirectUrl = normalizeUrl(configuration?.PortalUrl);

      navigate(redirectUrl);
    }
  }, [navigate, user?.accessToken, isConfigLoading]);

  const handleMicrosoftSignIn = () => {
    const { tenant, env } = getTenant();

    const envPart = (env ? `-${env}` : '') as `-${string}` | '';

    window.location.replace(`${API_URL}/api/auth/microsoft/${tenant}${envPart}`);
  };

  const handleB2CSignIn = () => {
    const { tenant, env } = getTenant();
    const envPart = (env ? `-${env}` : '') as `-${string}` | '';
    window.location.replace(`${API_URL}/api/auth/b2c/${tenant}${envPart}`);
  };

  const b2cBtn = !isLoadingB2CAuth && b2cAuth?.isB2cAuthEnabled && (
    <Button
      size="lg"
      variant="outlined"
      color="primary"
      className="flex w-full"
      startIcon={<Icons.Outlined.Social.MicrosoftIcon />}
      iconClassName="fill-brandingColor-primary-gradient mr-[14px]"
      onClick={handleB2CSignIn}
    >
      Continue with Microsoft B2C
    </Button>
  );

  return (
    <div className="flex items-center justify-center w-full h-full bg-[#EFF0F4]">
      {user?.accessToken && isConfigLoading ? (
        <Spinner
          withBackdrop
          fallbackText="Authenticating, please wait..."
        />
      ) : (
        <div className="flex items-center justify-center flex-col gap-[52px] p-[52px] rounded-2xl bg-textColor-white w-[550px]">
          <div className="flex items-center justify-center gap-[13px]">
            <Icon
              icon={<CalendarIcon />}
              className="fill-brandingColor-primary-gradient w-[40px] h-[40px]"
            />

            <span className="font-bold text-[40px] leading-10 text-black">
              <span className="text-brandingColor-primary-gradient">V</span>
              alorian
            </span>
          </div>

          <div className="flex flex-col w-full gap-8">
            {isLoadingB2CAuth ? (
              Array.from({ length: AUTH_BTN_SKELETON_COUNT }).map((_) => (
                <BtnSkeleton
                  key={uuidv4()}
                  size="lg"
                />
              ))
            ) : (
              <>
                <Button
                  size="lg"
                  color="primary"
                  className="flex w-full"
                  startIcon={<Icons.Outlined.Social.MicrosoftIcon />}
                  iconClassName="fill-brandingColor-primary-gradient mr-[14px]"
                  onClick={handleMicrosoftSignIn}
                >
                  Sign in with Microsoft
                </Button>

                {b2cBtn}
              </>
            )}
          </div>

          <div className="font-normal text-base text-textColor-secondary">
            {dayjs().year()}, Valorian, All rights Reserved
          </div>
        </div>
      )}
    </div>
  );
};

export { Auth };
