import { B2CAuthEnabled } from 'src/shared/types';
import { api } from 'src/store/api/api';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: () => ({
        url: '/auth/refresh-token',
        method: 'POST',
      }),
      invalidatesTags: () => ['Auth'],
      transformResponse: (response: {
        data: {
          accessToken: string;
        };
      }) => response.data,
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
    isB2CAuthEnabled: builder.query<B2CAuthEnabled, void>({
      query: () => ({
        url: '/auth/b2c/auth-enabled',
        method: 'GET',
      }),
      providesTags: () => ['Auth'],
      transformResponse: (response: { data: B2CAuthEnabled }) => response.data,
    }),
  }),
});

export const { useRefreshTokenMutation, useLogoutMutation, useIsB2CAuthEnabledQuery } = authApi;
export { authApi };
