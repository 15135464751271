import { FC, ReactNode, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Home,
  Main,
  Auth,
  Reports,
  Dashboard,
  Forms,
  SurveyForm,
  PublicSurveyForm,
  SurveyFormCreator,
  SurveyAnswers,
  SurveyAnswer,
  EditSurveyAnswer,
  Admin,
  AdminTableManager,
  C2ODashboard,
  Activities,
  ActivitiesDashboard,
  C2OAdmin,
  NotFound,
  ActivityReports,
  UnclosedFindings,
  UnclosedFindingsSettings,
  SafetyMap,
} from 'src/pages';
import { Role } from 'src/shared/types';
import { Layout } from 'src/shared/ui/layout';
import { selectCurrentUser } from 'src/store/slices';
import { getAzureUrl, isPathPublic, not } from 'src/shared/utils';
import {
  useGetPermissionsByRoleQuery,
  useGetPortalProviderSettingsQuery,
  useLogoutMutation,
} from 'src/store/api';
import { useGetProviderQuery } from 'src/store/api/provider';

import { ADMIN_ROLES, NOT_ADMIN_ROLES, PUBLIC_URLS, SKIP_QUERY } from './shared/constants';
import { AdminMap } from './pages/adminMap';
import { C2O_ROLES } from './shared/c2o/constants';
import { AbilityContext } from './app/context';
import { createAbilityFromPermissions } from './app/permissions';
import { Spinner } from './shared/ui/spinner';

const NAVBAR_ID = 'navbar-container';

type ProtectedRouteProps = {
  withSidebar?: boolean;
  withHeader?: boolean;
  withHeaderFilters?: boolean;
  withHeaderCollapseAll?: boolean;
  withHeaderWeekNavigation?: boolean;
  withHeaderToday?: boolean;
  withHeaderSearch?: boolean;
  withPreloader?: boolean;
  extraAllowedRoles?: Role[];
  forbiddenRoles?: Role[];
  fallbackUrl?: string;
  withUserInfo?: boolean;
  withNavigation?: boolean;
  navigationAlwaysOpen?: boolean;
  navigationContainerSelector?: string;
  headerSearchPlaceholder?: string;
};

type ProtectedRoutePropsWithChildren = ProtectedRouteProps & {
  children?: ReactNode;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  withPreloader = true,
  withSidebar = true,
  withHeader = true,
  withHeaderFilters = true,
  withHeaderCollapseAll = true,
  withHeaderWeekNavigation = true,
  withHeaderSearch = true,
  withHeaderToday = true,
  withUserInfo = true,
  withNavigation = true,
  // allow any user to visit any route until we implement a new permissions system
  extraAllowedRoles = Object.values(Role),
  forbiddenRoles = [],
  fallbackUrl = '/',
  navigationAlwaysOpen,
  ...props
}) => {
  const [logout] = useLogoutMutation();

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;
  const isUser = user?.ProviderRoleMatrix?.userRole === Role.User;

  const allowedRoles = [...ADMIN_ROLES, ...extraAllowedRoles].filter(
    (role) => !forbiddenRoles.includes(role),
  );

  if (isUser) {
    logout({});
    return <Navigate to="/auth" />;
  }

  if (not(user?.accessToken)) {
    return <Navigate to="/auth" />;
  }

  if (not(allowedRoles.includes(user?.ProviderRoleMatrix?.userRole as Role))) {
    return <Navigate to={fallbackUrl} />;
  }

  const renderOutlet = () => {
    if (navigationAlwaysOpen) {
      return (
        <div className="w-full flex">
          <div id={NAVBAR_ID} />

          <div>
            <Outlet />
          </div>
        </div>
      );
    }
    return <Outlet />;
  };

  return (
    <Layout
      withSidebar={withSidebar && not(isReadonly)}
      withHeader={withHeader}
      withPreloader={withPreloader}
      withHeaderFilters={withHeaderFilters}
      withHeaderCollapseAll={withHeaderCollapseAll}
      withHeaderWeekNavigation={withHeaderWeekNavigation}
      withHeaderSearch={withHeaderSearch}
      withHeaderToday={withHeaderToday}
      withUserInfo={withUserInfo}
      withNavigation={withNavigation}
      navigationAlwaysOpen={navigationAlwaysOpen}
      {...props}
    >
      {renderOutlet()}
    </Layout>
  );
};

const AdminRoute: FC<ProtectedRouteProps> = ({
  withPreloader = true,
  withSidebar = true,
  withHeader = true,
  withHeaderFilters = true,
  withHeaderCollapseAll = true,
  withHeaderWeekNavigation = true,
  withHeaderSearch = true,
  withHeaderToday = true,
  withUserInfo = true,
  withNavigation = true,
  fallbackUrl = '/',
  extraAllowedRoles = [],
}) => {
  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const allowedRoles = [...ADMIN_ROLES, ...extraAllowedRoles];

  if (not(user?.accessToken)) {
    return <Navigate to="/auth" />;
  }

  if (not(allowedRoles.includes(user?.ProviderRoleMatrix?.userRole as Role))) {
    return <Navigate to={fallbackUrl} />;
  }

  return (
    <Layout
      withSidebar={withSidebar && not(isReadonly)}
      withHeader={withHeader}
      withPreloader={withPreloader}
      withHeaderFilters={withHeaderFilters}
      withHeaderCollapseAll={withHeaderCollapseAll}
      withHeaderWeekNavigation={withHeaderWeekNavigation}
      withHeaderSearch={withHeaderSearch}
      withHeaderToday={withHeaderToday}
      withUserInfo={withUserInfo}
      withNavigation={withNavigation}
    >
      <Outlet />
    </Layout>
  );
};
const AdminRouteWrapper: FC<ProtectedRoutePropsWithChildren> = ({
  children,
  withPreloader = true,
  withSidebar = true,
  withHeader = true,
  withHeaderFilters = true,
  withHeaderCollapseAll = true,
  withHeaderWeekNavigation = true,
  withHeaderSearch = true,
  withHeaderToday = true,
  withUserInfo = true,
  withNavigation = true,
  fallbackUrl = '/',
  extraAllowedRoles = [],
  forbiddenRoles = [],
  ...props
}) => {
  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const allowedRoles = [...ADMIN_ROLES, ...extraAllowedRoles].filter(
    (role) => !forbiddenRoles.includes(role),
  );

  if (not(user?.accessToken)) {
    return <Navigate to="/auth" />;
  }

  if (not(allowedRoles.includes(user?.ProviderRoleMatrix?.userRole as Role))) {
    return <Navigate to={fallbackUrl} />;
  }

  return (
    <Layout
      withSidebar={withSidebar && not(isReadonly)}
      withHeader={withHeader}
      withPreloader={withPreloader}
      withHeaderFilters={withHeaderFilters}
      withHeaderCollapseAll={withHeaderCollapseAll}
      withHeaderWeekNavigation={withHeaderWeekNavigation}
      withHeaderSearch={withHeaderSearch}
      withHeaderToday={withHeaderToday}
      withUserInfo={withUserInfo}
      withNavigation={withNavigation}
      {...props}
    >
      {children}
    </Layout>
  );
};

const FormsRoute: FC<Pick<ProtectedRouteProps, 'forbiddenRoles'>> = (props) => {
  return (
    <ProtectedRoute
      extraAllowedRoles={[Role.SurveyUser, Role.SurveyReadonly, Role.SiteSuperUser, ...C2O_ROLES]}
      withPreloader={false}
      withSidebar={false}
      withHeaderCollapseAll={false}
      withHeaderToday={false}
      withHeaderFilters={false}
      withHeaderWeekNavigation={false}
      withHeaderSearch={false}
      withUserInfo
      withNavigation
      fallbackUrl="/forms"
      {...props}
    />
  );
};

const AdminFormsRoute: FC<Pick<ProtectedRouteProps, 'extraAllowedRoles'>> = (props) => {
  return (
    <AdminRoute
      withPreloader={false}
      withSidebar={false}
      withHeaderCollapseAll={false}
      withHeaderToday={false}
      withHeaderFilters={false}
      withHeaderWeekNavigation={false}
      withHeaderSearch={false}
      fallbackUrl="/forms"
      withUserInfo
      withNavigation
      {...props}
    />
  );
};

const PublicRoute: FC<
  Omit<ProtectedRouteProps, 'extraAllowedRoles' | 'forbiddenRoles' | 'fallbackUrl'>
> = ({
  withPreloader = false,
  withSidebar = false,
  withHeader = false,
  withHeaderFilters = false,
  withHeaderCollapseAll = false,
  withHeaderWeekNavigation = false,
  withHeaderSearch = false,
  withHeaderToday = false,
  withUserInfo = false,
  withNavigation = false,
}) => {
  return (
    <Layout
      withSidebar={withSidebar}
      withHeader={withHeader}
      withPreloader={withPreloader}
      withHeaderFilters={withHeaderFilters}
      withHeaderCollapseAll={withHeaderCollapseAll}
      withHeaderWeekNavigation={withHeaderWeekNavigation}
      withHeaderSearch={withHeaderSearch}
      withHeaderToday={withHeaderToday}
      withUserInfo={withUserInfo}
      withNavigation={withNavigation}
    >
      <Outlet />
    </Layout>
  );
};
const PublicFormsRoute: FC = (props) => {
  return (
    <PublicRoute
      withPreloader={false}
      withSidebar={false}
      withHeader
      withHeaderCollapseAll={false}
      withHeaderToday={false}
      withHeaderFilters={false}
      withHeaderWeekNavigation={false}
      withHeaderSearch={false}
      withUserInfo={false}
      withNavigation={false}
      {...props}
    />
  );
};

const C2ORoute: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <AdminRouteWrapper
      withPreloader={false}
      withSidebar={false}
      withHeader
      withHeaderCollapseAll={false}
      withHeaderToday={false}
      withHeaderFilters={false}
      withHeaderWeekNavigation={false}
      withHeaderSearch={false}
      withUserInfo
      navigationAlwaysOpen
      navigationContainerSelector={`#${NAVBAR_ID}`}
      // TODO: update allowed roles for c2o
      extraAllowedRoles={Object.values(Role)}
    >
      <div className="w-full flex">
        <div id={NAVBAR_ID} />

        <div className="w-full">{children}</div>
      </div>
    </AdminRouteWrapper>
  );
};

const RootProvider = () => {
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const isPublicPage = isPathPublic(location.pathname, PUBLIC_URLS);

  const { data: permissions, isLoading: isLoadingPermissions } = useGetPermissionsByRoleQuery('', {
    skip: !user,
  });

  const ability = createAbilityFromPermissions(permissions || []);

  useEffect(() => {
    if (!user && !isPublicPage) {
      navigate('/auth');
    }
  }, [user, navigate, isPublicPage]);

  if (isLoadingPermissions) {
    return (
      <Spinner
        withBackdrop
        fallbackText="Loading ..."
      />
    );
  }

  return (
    <AbilityContext.Provider value={ability}>
      <Outlet />
    </AbilityContext.Provider>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootProvider />,
    children: [
      {
        path: '/auth',
        element: <Auth />,
      },
      {
        path: '/',
        element: (
          <ProtectedRoute
            extraAllowedRoles={Object.values(Role)}
            withPreloader={false}
            withSidebar={false}
            withHeader
            withHeaderCollapseAll={false}
            withHeaderFilters={false}
            withHeaderToday={false}
            withHeaderWeekNavigation={false}
            withHeaderSearch={false}
          />
        ),
        children: [
          {
            index: true,
            element: <Home />,
          },
        ],
      },
      {
        path: '/scheduler',
        element: <ProtectedRoute forbiddenRoles={[Role.SiteSuperUser]} />,
        children: [
          {
            index: true,
            element: <Main />,
          },
        ],
      },
      {
        path: '/ta/:activityId',
        element: (
          <ProtectedRoute
            withSidebar={false}
            withHeaderToday={false}
            withHeaderCollapseAll={false}
            withHeaderSearch={false}
            withHeaderWeekNavigation={false}
            withHeaderFilters={false}
            forbiddenRoles={[Role.SiteSuperUser, Role.TADashboardUser]}
          />
        ),
        children: [
          {
            index: true,
            element: <Activities />,
          },
        ],
      },
      {
        path: '/ta',
        element: (
          <ProtectedRoute
            withSidebar={false}
            withHeaderToday={false}
            withHeaderCollapseAll={false}
            withHeaderSearch={false}
            withHeaderWeekNavigation={false}
            withHeaderFilters={false}
            forbiddenRoles={[Role.SiteSuperUser, Role.TADashboardUser]}
          />
        ),
        children: [
          {
            index: true,
            element: <Activities />,
          },
        ],
      },
      {
        path: '/ta/dashboard',
        element: (
          <ProtectedRoute
            withSidebar={false}
            withHeaderToday={false}
            withHeaderCollapseAll={false}
            withHeaderSearch={false}
            withHeaderWeekNavigation={false}
            withHeaderFilters={false}
            forbiddenRoles={[Role.SiteSuperUser, Role.TATrackerUser]}
          />
        ),
        children: [
          {
            index: true,
            element: <ActivitiesDashboard />,
          },
        ],
      },
      {
        path: '/ta/reports',
        element: (
          <ProtectedRoute
            withSidebar={false}
            withHeaderToday={false}
            withHeaderCollapseAll={false}
            withHeaderSearch={false}
            withHeaderWeekNavigation={false}
            withHeaderFilters={false}
            forbiddenRoles={[Role.SiteSuperUser]}
          />
        ),
        children: [
          {
            index: true,
            element: <ActivityReports />,
          },
        ],
      },
      {
        path: '/actions/unclosed-findings',
        element: (
          <ProtectedRoute
            withSidebar={false}
            withHeaderToday={false}
            withHeaderCollapseAll={false}
            withHeaderSearch={false}
            withHeaderWeekNavigation={false}
            withHeaderFilters={false}
            forbiddenRoles={[Role.SiteSuperUser]}
          />
        ),
        children: [
          {
            index: true,
            element: <UnclosedFindings />,
          },
        ],
      },
      {
        path: '/actions/unclosed-findings/settings',
        element: (
          <ProtectedRoute
            withSidebar={false}
            withHeaderToday={false}
            withHeaderCollapseAll={false}
            withHeaderSearch={false}
            withHeaderWeekNavigation={false}
            withHeaderFilters={false}
            forbiddenRoles={NOT_ADMIN_ROLES}
          />
        ),
        children: [
          {
            index: true,
            element: <UnclosedFindingsSettings />,
          },
        ],
      },
      {
        path: '/dashboard',
        element: (
          <ProtectedRoute
            withPreloader={false}
            withSidebar={false}
            withHeaderCollapseAll={false}
            withHeaderToday={false}
            forbiddenRoles={[Role.SiteSuperUser]}
          />
        ),
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
        ],
      },
      {
        path: '/forms',
        element: <FormsRoute />,
        children: [
          {
            index: true,
            element: <Forms />,
          },
        ],
      },

      {
        path: '/forms/create',
        element: <AdminFormsRoute />,
        children: [
          {
            index: true,
            element: <SurveyFormCreator type="create" />,
          },
        ],
      },
      {
        path: '/forms/:formId',
        element: <FormsRoute forbiddenRoles={[Role.SurveyReadonly]} />,
        children: [
          {
            index: true,
            element: <SurveyForm />,
          },
        ],
      },
      {
        path: '/public-forms/:formId',
        element: <PublicFormsRoute />,
        children: [
          {
            index: true,
            element: <PublicSurveyForm />,
          },
        ],
      },
      {
        path: '/forms/:formId/answers',
        element: <FormsRoute />,
        children: [
          {
            index: true,
            element: <SurveyAnswers />,
          },
        ],
      },
      {
        path: '/forms/:formId/edit',
        element: <AdminFormsRoute />,
        children: [
          {
            index: true,
            element: <SurveyFormCreator type="edit" />,
          },
        ],
      },
      {
        path: '/forms/:formId/answers/:answerId',
        element: <FormsRoute />,
        children: [
          {
            index: true,
            element: <SurveyAnswer />,
          },
        ],
      },
      {
        path: '/public-forms/:formId/answers/:answerId',
        element: <PublicFormsRoute />,
        children: [
          {
            index: true,
            element: <SurveyAnswer isPublic />,
          },
        ],
      },
      {
        path: '/forms/:formId/answers/:answerId/edit',
        element: <AdminFormsRoute extraAllowedRoles={[Role.SurveyUser]} />,
        children: [
          {
            index: true,
            element: <EditSurveyAnswer />,
          },
        ],
      },
      {
        path: '/reports',
        element: (
          <ProtectedRoute
            withPreloader={false}
            withSidebar={false}
            withHeaderCollapseAll={false}
            withHeaderToday={false}
            withHeaderWeekNavigation={false}
            withHeaderSearch={false}
            forbiddenRoles={[Role.SiteSuperUser]}
          />
        ),
        children: [
          {
            index: true,
            element: <Reports />,
          },
        ],
      },
      {
        path: '/admin',
        element: (
          <AdminRouteWrapper
            withHeaderCollapseAll={false}
            withHeaderSearch={false}
            withHeaderToday={false}
            withHeaderWeekNavigation={false}
            withSidebar={false}
            withHeaderFilters={false}
            withPreloader={false}
            extraAllowedRoles={[Role.SiteSuperUser]}
          >
            <Admin />
          </AdminRouteWrapper>
        ),
        children: [
          {
            path: '/admin/:modelName',
            element: <AdminTableManager />,
          },
        ],
      },
      {
        path: '/c2o',
        element: (
          <C2ORoute>
            <C2ODashboard />
          </C2ORoute>
        ),
      },
      {
        path: '/c2o/:modelName',
        element: (
          <C2ORoute>
            <C2OAdmin />
          </C2ORoute>
        ),
        children: [
          {
            index: true,
            element: <AdminTableManager backButtonDisabled />,
          },
          {
            path: 'map',
            element: <AdminMap />,
          },
        ],
      },
      {
        path: '/safety/map',
        element: (
          <ProtectedRoute
            withPreloader={false}
            withSidebar={false}
            withHeaderCollapseAll={false}
            withHeaderToday={false}
            headerSearchPlaceholder="Search by details"
            withHeaderWeekNavigation={false}
          />
        ),
        children: [
          {
            index: true,
            element: <SafetyMap />,
          },
        ],
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '*',
        element: (
          <Navigate
            to="/404"
            replace
          />
        ),
      },
    ],
  },
]);

const Router: FC = () => {
  const user = useSelector(selectCurrentUser);
  const { data: configuration } = useGetPortalProviderSettingsQuery({});

  const { data: provider } = useGetProviderQuery('', {
    skip: !user || window.location.pathname.startsWith(SKIP_QUERY.publicForms),
  });

  useEffect(() => {
    // * Set favicon and title dynamically
    const existingFavicon = document.querySelector("link[rel*='icon']");

    const favicon = existingFavicon || (document.createElement('link') as HTMLLinkElement);

    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }

    if (favicon instanceof HTMLLinkElement) {
      favicon.type = 'image/x-icon';
      favicon.rel = 'icon';
      favicon.href = configuration?.Favicon
        ? getAzureUrl(provider?.FileRootPath, configuration?.Favicon)
        : '';

      document.title = configuration?.PageTitle || 'Scheduling Board';

      document.head.appendChild(favicon);
    }
  }, [configuration, provider]);

  return <RouterProvider router={router} />;
};

export { Router };
