import { forwardRef, memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, TextFieldProps } from '@mui/material';

import { COLORS } from './helpers/constants';
import { generateReadOnlyStyles } from './helpers/functions';

type InputFieldProps = TextFieldProps & {
  readOnly?: boolean;
};

const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  ({ sx, readOnly, ...props }, ref) => {
    const theme = useTheme();
    return (
      <TextField
        sx={{
          width: '100%',
          ...(readOnly && generateReadOnlyStyles(COLORS, theme)),
          ...sx,
        }}
        InputProps={{
          readOnly,
        }}
        {...props}
        ref={ref}
      />
    );
  },
);

InputField.displayName = 'InputField';
const memoizedInputField = memo(InputField);
export { memoizedInputField as InputField };
