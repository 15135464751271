import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type RootState } from 'src/store';

import { authApi } from '../api/auth';

export type User = {
  id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  ProviderRoleMatrix: {
    ProviderRoleMatrixEmail: string;
    userRole: string;
    OwnerLocationID: string;
  };
  accessToken: string;
};

type InitialState = {
  user:
    | (User & {
        accessToken: string;
      })
    | null;
};

const initialState: InitialState = {
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.clear();
      localStorage.removeItem('persist:root');
    },
    login: (state, { payload }: PayloadAction<User>) => {
      state.user = {
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.logout.matchPending, (state) => {
      state.user = null;
      localStorage.clear();
      localStorage.removeItem('persist:root');
    });
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.user;
