export const GLOBAL_LAYOUT = {
  header: {
    minHeight: 72,
  },
  formPageSidebar: {
    expandedWidth: 316,
    collapsedWidth: 80,
  },
} as const;

export const BREAKPOINTS = {
  sm: 640,
} as const;

export const DEFAULT_SIZE_MUI_SPINNER = {
  button: 24,
} as const;
