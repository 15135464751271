import { Dispatch, SetStateAction } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { TagType } from 'src/shared/types';
import { capitalizeFirst } from 'src/shared/utils';

interface CreateToolbarProps {
  modelName: TagType;
  modelLabel?: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

const CreateToolbar = (props: CreateToolbarProps) => {
  const { setIsOpen, disabled: isDisabled, modelName, modelLabel } = props;

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <Button
      disabled={isDisabled}
      color="primary"
      startIcon={<AddIcon />}
      onClick={handleClick}
    >
      {`Add ${capitalizeFirst(modelLabel || modelName)}`}
    </Button>
  );
};

export { CreateToolbar };
