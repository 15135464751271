export enum WorkTrack {
  OnTrack = 'On-track',
  Delayed = 'Delayed',
  NotStarted = 'Not Started',
  OnHold = 'On-hold',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Updated = 'Updated',
  Scheduled = 'Scheduled',
  OffTrack = 'Off-track',
}
export type ActivityHealth = 'Scheduled' | 'OnTrack' | 'Delayed' | 'OffTrack';
export type ActivityTrack = 'NotStarted' | 'InProgress' | 'Completed';
export type ActivityUpdated = 'Updated';

export interface ActivityUpdate {
  id: string;
  jobNumber: string;
  reportedBy: string;
  workStatus: ActivityTrack;
  workProgress?: number;
  workTrack: ActivityHealth;
  startWork?: Date;
  endWork?: Date;
  actualStart?: Date;
  actualEnd?: Date;
  delayCategoryID?: string;
  delayCategoryName?: string;
  activityName?: string;
  providerId: string;
  providerName: string;
  providerTeam: {
    id: string;
    name: string;
  };
  providerTeamId: string;
  notes?: string;
  percentComplete?: number;
  createdAt: string;
  equipment?: string;
  type?: string;
}

export interface ActivityJob {
  id: string;
  jobNumber: string;
  reportedBy: string;
  workStatus: ActivityTrack;
  workProgress: number;
  workTrack: ActivityHealth;
  startWork: Date;
  endWork: Date;
  actualStart?: Date;
  actualEnd?: Date;
  delayCategoryID?: string;
  delayCategoryName?: string;
  activityName: string;
  providerId: string;
  providerName: string;
  providerTeam: {
    id: string;
    name: string;
  };
  providerTeamId: string;
  updates: null | ActivityUpdate[];
  notes?: string;
  percentComplete?: number;
  equipment?: string;
  equipmentTypeId: string;
  delayDuration?: string;
  durationPercentage?: number;
}

export type UpdateActivityParams = {
  id: string;
  actualStart?: Date;
  actualEnd?: Date;
  notes?: string;
  delay?: string;
  percentComplete: number;
};

export type SetEditActivityParams = {
  id: string;
  startWork: Date;
  endWork: Date;
  crew: string;
  workStatus: WorkTrack;
};

export enum TimePeriod {
  Day = 'day',
  '3Days' = '3days',
  Week = 'week',
  Month = 'month',
  Custom = 'custom',
}

export type ActivityFilters = {
  jobID?: string[];
  provider?: string[];
  crew?: string[];
  status?: string[];
  health?: string[];
  delayCategory?: string[];
};

export interface ParamsById {
  id: string;
}
export interface ActivityState {
  id: string;
  name: string;
  reportedBy: string;
  jobNumber: string;
  activityId: string;
  progress: number;
  providerId: string;
  provider: ProviderActivity;
  providerTeamId: string;
  providerTeam: ProviderTeam;
  notes?: string;
  delayCategoryID?: string;
  delayCategory?: {
    DelayCategory: string;
    DelayCategoryID: string;
  };
  equipment?: string;
  equipmentTypeId?: string;
  startDate: string;
  endDate: string;
  health: ActivityHealth;
  track: 'NotStarted' | 'InProgress' | 'Completed';
  createdAt: string;
  delayDuration: string;
  isSystemUpdate?: boolean;
  durationPercentage?: number;
}

export enum ActivityEntity {
  Activities = 'Activities',
  States = 'Updates',
}

export type EntityRecord = {
  label: ActivityEntity;
  amount: number;
};

export type ActivityStateSelect = Omit<
  ActivityState,
  'createdAt' | 'delayDuration' | 'delayCategoryID'
> & {
  jobName?: string;
};

type ActivityStateWithOwnerLocation = ActivityStateSelect & {
  activity: {
    OwnerLocation: {
      LocationTimezone: string;
      OwnerName: string;
    };
  };
  delayDuration?: string;
  delayStart?: string;
  delayEnd?: string;
};

type PaginationData = {
  total: number;
  page: string;
  pageSize: string;
};

export interface Activity {
  id: string;
  activityStates: ActivityState[];
  ownerLocationID: string;
  OwnerLocation: {
    LocationTimezone: string;
    OwnerName: string;
  };
  team: {
    id: string;
    name: string;
    providerId: string;
  };
  actualState: ActivityState;
}
export type GraphDate = {
  date: string;
};
export type TrackType = {
  [key in ActivityTrack]: number;
};
export type GraphTracks = {
  tracks: TrackType;
};

export type GraphData = GraphDate &
  GraphTracks & {
    [key: string]: number;
  };

export type GraphPieData = {
  name: string;
  value: number;
};

export interface CreateNewActivity {
  teamId: string;
  ownerLocationID: string;
  id?: string;
}

export interface CreateNewState {
  reportedBy: string;
  jobNumber: string;
  jobName: string;
  progress: number;
  providerId: string;
  providerTeamId: string;
  startDate: string;
  endDate: string;
  health: 'OnTrack' | 'Delayed' | 'OnHold';
  track: 'NotStarted' | 'InProgress' | 'Completed';
  name?: string;
  notes?: string;
  delay?: string;
  delayCategoryID?: string;
  equipment?: string;
  equipmentTypeId?: string;
}

export type ActivityWithState = CreateNewActivity & CreateNewState;

export interface ActivityStateBody {
  name: string;
  reportedBy: string;
  jobNumber: string;
  activityId: string;
  progress: number;
  providerId: string;
  providerTeamId: string;
  notes?: string;
  delayCategoryID?: string;
  equipment?: string;
  equipmentTypeId?: string;
  startDate: string;
  endDate: string;
  health: ActivityHealth;
  track: 'NotStarted' | 'InProgress' | 'Completed';
  delayStart?: string;
  delayEnd?: string;
  delayDuration?: string;
}
export interface ProviderTeam {
  id: string;
  name: string;
  providerId: string;
}
export interface ProviderActivity {
  ProviderID: string;
  ProviderName: string;
}
export interface Process {
  id: string;
  activity: Activity[];
}

export interface DelayCategory {
  DelayCategoryID: string;
  DelayCategory: string;
}

export interface ActivityByIdParams {
  id: string;
}
export interface ProcessByIdParams extends ActivityFilters {
  id: string;
  startDate: string;
  endDate: string;
  jobIdSearch?: string;
}
export interface ProcessesParams extends ActivityFilters {
  startDate: string;
  endDate: string;
  jobIdSearch?: string;
}

export interface DashboardFilters {
  providerId: string[];
  track: ActivityTrack[];
  startDate: string;
  endDate: string;
}
export interface NewJobsPathResponse {
  name: string;
  ta_fileprocessor_api_key: string;
  ta_fileprocessor_endpoint: string;
}
export interface NewJobsPathPayload {
  tenant: string;
}

export interface FreeByTimeProviderTeamsPayload extends ParamsById {
  startDate: string;
  endDate: string;
}

export interface GetActivityProvidersParams {
  startDate?: string;
  endDate?: string;
}
export interface EquipmentType {
  EquipmentType: string;
  EquipmentTypeID: string;
}

export type Filter = { label: string; options: string[] };

export type AllActivities = {
  filters: Filter[];
  allActivities: {
    id: string;
    activityStates: ActivityStateSelect[];
    team: {
      name: string;
    };
    OwnerLocation: {
      OwnerName: string | null;
      LocationTimezone: string;
    } | null;
    actualState: ActivityStateSelect | null;
  }[];
  pagination: PaginationData;
  totalStates: number;
};

export type AllActivityStates = {
  filters: Filter[];
  allActivities: ActivityStateWithOwnerLocation[];
  pagination: PaginationData;
  totalActivities: number;
};

export enum EnumActivityTrack {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export enum ActivityHealthEnum {
  Scheduled = 'Scheduled',
  OnTrack = 'OnTrack',
  Delayed = 'Delayed',
  OffTrack = 'OffTrack',
}

export enum SearchableTAFilters {
  JobID = 'Job ID',
}
