import { ActionReceiver, ActionState } from 'src/shared/types/actions';
import { TableHeaderType } from 'src/shared/ui/table';

import { ActionStatus } from './types';

export const headersHistoryList: TableHeaderType<ActionState>[] = [
  {
    title: 'ID',
    field: 'id',
    className: 'w-max',
    canSort: true,
    render: (action) => action.id || '',
  },
  {
    title: 'Status',
    field: 'status',
    className: 'w-max',
    canSort: true,
    render: (action) => {
      if (action.status in ActionStatus) {
        return ActionStatus[action.status as keyof typeof ActionStatus];
      }
      return action.status;
    },
  },
  {
    title: 'Assigned to',
    field: 'assignedTo',
    className: 'w-max',
    canSort: true,
    render: (action) => action.assignedTo || '',
  },
  {
    title: 'Updated By',
    field: 'updatedBy',
    className: 'w-max',
    canSort: true,
    render: (action) => action.updatedBy || '',
  },
  {
    title: 'Notes',
    field: 'notes',
    className: 'w-max',
    canSort: true,
    render: (action) => action.notes || '',
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    className: 'w-max',
    canSort: true,
    render: (action) => action.updatedAt || '',
  },
];

export const headersActionReceivers: TableHeaderType<ActionReceiver>[] = [
  {
    title: 'First Name',
    field: 'firstName',
    className: 'w-max',
    canSort: true,
    render: (receiver) => receiver.firstName || '',
  },
  {
    title: 'Last Name',
    field: 'lastName',
    className: 'w-max',
    canSort: true,
    render: (receiver) => receiver.lastName || '',
  },
  {
    title: 'Email',
    field: 'email',
    className: 'w-max',
    canSort: true,
    render: (receiver) => receiver.email,
  },
  {
    title: 'Is Active',
    field: 'isActive',
    className: 'w-max',
    canSort: true,
    render: (receiver) => (receiver.isActive ? 'Active' : 'Not Active'),
  },
];

export const initialActionReceiversValues = {
  firstName: '',
  lastName: '',
  email: '',
  isActive: true,
};

export const ACTION_ID = 'actionId';
export const SEARCH_FIELD_DEBOUNCE_DELAY = 500;
