import { forwardRef, HTMLAttributes, PropsWithChildren, ReactNode, useRef, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/filled/chevrons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/filled/chevrons/chevron-up.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { Size as IconSize } from 'src/shared/ui/iconButton/iconButton.types';
import { not } from 'src/shared/utils';

type AccordionProps = PropsWithChildren &
  Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
    defaultOpen?: boolean;
    iconPosition?: 'left' | 'right';
    iconSize?: IconSize;
    accordionTitle: ReactNode;
    preTitle?: ReactNode;
    shouldRenderTitleAsFirstCard?: boolean;
    open?: boolean;
    hideIcon?: boolean;
    handleAccordionChange?: (isOpen: boolean) => void;
  };

const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      iconPosition = 'right',
      iconSize = 'md',
      children,
      accordionTitle: title,
      preTitle,
      shouldRenderTitleAsFirstCard,
      hideIcon = false,
      className,
      handleAccordionChange,
      open,
      defaultOpen: isDefaultOpen = true,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [internalIsOpen, setIsOpen] = useState(isDefaultOpen);
    const titleRef = useRef<HTMLDivElement>(null);
    const defaultGap = 32;
    const isOpen = typeof open !== 'undefined' ? open : internalIsOpen;

    const handleAccordionClick = () => {
      if (handleAccordionChange) {
        handleAccordionChange(isOpen);
      } else {
        setIsOpen(!isOpen);
      }
    };

    return (
      <div
        {...props}
        ref={ref}
        className={clsx('flex flex-col w-full', className, not(isOpen) && 'overflow-hidden')}
        style={{
          ...props?.style,
        }}
      >
        <div
          ref={titleRef}
          className="flex flex-col justify-between w-full cursor-pointer"
          onClick={handleAccordionClick}
        >
          {shouldRenderTitleAsFirstCard && (
            <div className="flex justify-between items-center mb-3 h-[18px]">{preTitle}</div>
          )}

          <div className="flex items-center justify-between gap-x-2 grow">
            {iconPosition === 'left' && !hideIcon && (
              <IconButton
                onClick={handleAccordionClick}
                className="bg-bgColor-white p-1"
                size={iconSize}
              >
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </IconButton>
            )}

            {title}

            {iconPosition === 'right' && !hideIcon && (
              <IconButton
                onClick={handleAccordionClick}
                className="bg-bgColor-white p-1"
                size={iconSize}
              >
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </IconButton>
            )}
          </div>
        </div>

        {!!children && isOpen && (
          <div
            className={clsx('transition-[opacity] duration-300 ease-in-out grow')}
            style={{
              opacity: Number(isOpen),
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  },
);

Accordion.displayName = 'Accordion';

export { Accordion };
export type { AccordionProps };
