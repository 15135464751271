import { OwnerPersonnelCreateBody } from 'src/shared/types';
import { api } from 'src/store/api/api';

const ownerPersonnelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createOwnerPersonnel: builder.mutation<OwnerPersonnelCreateBody, OwnerPersonnelCreateBody>({
      query: (body) => ({
        url: '/admin/owners-personnel',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: OwnerPersonnelCreateBody }) => response.data,
    }),
    getOwnerPersonnelByMail: builder.query<OwnerPersonnelCreateBody[], { email: string }>({
      query: ({ email }) => ({
        url: '/admin/owners-personnel/get-by-mail',
        params: {
          email,
        },
      }),
      transformResponse: (response: { data: OwnerPersonnelCreateBody[] }) => response.data,
    }),
  }),
});

export const { useCreateOwnerPersonnelMutation, useGetOwnerPersonnelByMailQuery } =
  ownerPersonnelApi;
