import { useAbility } from '@casl/react';
import { AbilityContext } from 'src/app/context';
import { Action, Actions, Subjects } from '../types';

export const useAppAbility = () => useAbility(AbilityContext);
export const usePermissions = (subject: Subjects) => {
  const { can } = useAppAbility();

  const checkPermission = (action: Actions) => can(action, subject);

  return {
    can: {
      read: checkPermission(Action.Read),
      create: checkPermission(Action.Create),
      update: checkPermission(Action.Update),
      delete: checkPermission(Action.Delete),
      manage: checkPermission(Action.Manage),
    },
  };
};
