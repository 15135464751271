import { TextField, Autocomplete, MenuItem, AutocompleteProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { RelationOption } from 'src/shared/types';
import { CustomValueInput } from './ui/customValueInput';
import { InputField } from '../inputField';
import { useEffect } from 'react';

type Item = RelationOption | string;

type BasicSelectAutocompleteProps<T> = {
  items: T[];
  selectedItem: T | null;
  label?: string;
  handleChange?: (newValue: T | null) => void;
  readOnly?: boolean;
  required?: boolean;
  error?: boolean;
  customRelationOptions?: T[];
  disabled?: boolean;
  withCustomValue?: boolean;
  onCustomValue?: (value: RelationOption) => void;
  validateOnItemsChange?: boolean;
} & Omit<AutocompleteProps<T, true, false, false, 'div'>, 'renderInput' | 'options'>;

const BasicSelectAutocomplete = <T extends RelationOption | string>({
  items,
  selectedItem,
  handleChange,
  label,
  readOnly = false,
  required = false,
  error = false,
  customRelationOptions = [],
  disabled = false,
  onCustomValue,
  withCustomValue = false,
  validateOnItemsChange = true,
}: BasicSelectAutocompleteProps<T>) => {
  const getItemLabel = (item: Item) => (typeof item === 'string' ? item : item.label);
  const getItemValue = (item: Item) => (typeof item === 'string' ? item : item.value);

  useEffect(() => {
    if (!validateOnItemsChange) {
      return;
    }

    const value = getItemValue(selectedItem || '');

    const currentItem = items.find((item) => getItemValue(item) === value);

    if (!currentItem && handleChange) {
      handleChange(null);
    }
  }, [items, validateOnItemsChange, selectedItem, handleChange, getItemValue]);

  return (
    <Autocomplete
      options={[...customRelationOptions, ...items]}
      getOptionLabel={(option) => getItemLabel(option)}
      isOptionEqualToValue={(option, value) => getItemValue(option) === getItemValue(value)}
      value={selectedItem}
      onChange={(_event, newValue) => {
        if (handleChange) {
          handleChange(newValue || null);
        }
      }}
      sx={{
        '& .MuiOutlinedInput-input': {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          error={error}
          required={required}
          variant="outlined"
          label={label || 'Select value'}
          placeholder={label || 'Select value'}
          readOnly={readOnly}
        />
      )}
      readOnly={readOnly}
      disabled={disabled}
      popupIcon={
        withCustomValue && onCustomValue ? <CustomValueInput onCustomValue={onCustomValue} /> : null
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={getItemValue(option)}
          value={getItemValue(option)}
          sx={{ justifyContent: 'space-between' }}
        >
          {getItemLabel(option)}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />
  );
};

export { BasicSelectAutocomplete };
