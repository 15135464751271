const COLORS = {
  readOnly: {
    backgroundColor: '#f5f5f5',
    textColor: '#6c757d',
    borderColor: '#6c757d',
  },
} as const;

type ColorsType = typeof COLORS;

export { COLORS };
export type { ColorsType };
