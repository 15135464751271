import { api } from 'src/store/api/api';
import { DashboardAdminMetric } from 'src/shared/types';

const adminMetricsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getC2OAdminMetrics: builder.query<DashboardAdminMetric[], ''>({
      query: () => ({
        url: '/admin/metrics/c2o',
        method: 'GET',
      }),

      providesTags: ['Metrics'],

      transformResponse: (response: { data: DashboardAdminMetric[] }) => response.data,
    }),
  }),
});

export const { useGetC2OAdminMetricsQuery } = adminMetricsApi;
