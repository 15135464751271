import { ActionDocumentation } from 'src/shared/types/actions';

export enum ActionStatus {
  Open = 'Open',
  InProgress = 'In Progress',
  Pending = 'Pending Feedback',
  Closed = 'Closed',
}

export interface Action {
  id: string;
  status: string;
  shortDescription?: string;
  assignedTo?: string;
  refinery?: string;
  updatedAt?: string;
  createdAt: string;
  responseId?: string;
  unitId?: string;
  unitName?: string;
  type: string;
  actionDocumentation: ActionDocumentation[];
}
