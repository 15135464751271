import { useState, FC } from 'react';
import { Box, Menu, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RelationOption } from 'src/shared/types';
import { InputField } from '../../inputField';

type CustomValueInputProps = {
  onCustomValue: (value: RelationOption) => void;
};
const CustomValueInput: FC<CustomValueInputProps> = ({ onCustomValue }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: string) => {
    setInputValue(value);
  };

  const addCustomValue = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (inputValue.trim() && inputValue.trim() !== '') {
      onCustomValue({
        value: inputValue.trim(),
        label: inputValue.trim(),
      });
      setInputValue('');
      handleClose();
    }
  };

  return (
    <>
      <Box onClick={handleClick}>
        <AddIcon />
      </Box>

      <Menu
        id="basic-menu"
        sx={{
          zIndex: 1000000,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          'sx': {
            padding: '1rem',
            minWidth: '20rem',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
          }}
        >
          <InputField
            size="small"
            sx={{ width: '100%' }}
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Add new item"
          />
          <IconButton
            onClick={addCustomValue}
            color="primary"
            aria-label="add to shopping cart"
            disabled={inputValue.trim().length === 0}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Menu>
    </>
  );
};

export { CustomValueInput };
