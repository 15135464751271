import { FC, useMemo } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useFormik } from 'formik';
import { z } from 'zod';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import {
  useAddNewDocumentMutation,
  useDeleteDocumentMutation,
  useGetActionByIdQuery,
} from 'src/store/api';
import { showToastErrorMessage } from 'src/shared/utils';
import { Spinner } from 'src/shared/ui/spinner';
import { InputFileUpload } from 'src/shared/ui/inputFileUpload';
import { Icons } from 'src/assets/icons';

import { getActionDocuments, getFileName } from '../../helpers/functions';
import { addFileToActionSchema } from '../../helpers/validation';

interface ActionFilesModalProps {
  isOpen: boolean;
  closeModal: () => void;
  actionId: string;
  onDocumentChange: () => void;
}

const ActionFilesModal: FC<ActionFilesModalProps> = ({
  isOpen,
  closeModal,
  actionId,
  onDocumentChange,
}) => {
  const toggleModal = () => {
    closeModal();
  };

  const {
    data: actionData,
    isLoading: isLoadingActionData,
    isFetching,
    refetch,
  } = useGetActionByIdQuery(
    {
      id: actionId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [addDocument, { isLoading: isLoadingNewDoc }] = useAddNewDocumentMutation();

  const { values, handleChange, handleSubmit, resetForm } = useFormik({
    onSubmit: async (data: z.infer<typeof addFileToActionSchema>) => {
      try {
        const formData = new FormData();

        formData.append('modelName', 'actions');

        formData.append('entityId', actionId);

        formData.append('files', data.files[0]);

        await addDocument(formData);

        resetForm();
        refetch();
        onDocumentChange();
      } catch (error) {
        showToastErrorMessage(`There was an error trying to add document`);
      }
    },
    validationSchema: toFormikValidationSchema(addFileToActionSchema),
    initialValues: {
      files: [],
    },
    enableReinitialize: true,
  });

  const handleFileChange = (files: File[]) => {
    handleChange({
      target: {
        name: 'files',
        value: files,
      },
    });
  };

  const actionFiles = useMemo(() => {
    if (!actionData) {
      return [];
    }

    return getActionDocuments(actionData.action.actionDocumentation);
  }, [actionData]);

  const [deleteDocument, { isLoading: isDeleting }] = useDeleteDocumentMutation();

  const deleteFile = async (fileId: string) => {
    try {
      await deleteDocument({ id: fileId }).unwrap();
      onDocumentChange();
    } catch (error) {
      showToastErrorMessage('There was an error trying to delete document');
    }
  };

  const isLoading = isLoadingActionData || isDeleting || isLoadingNewDoc || isFetching;

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px] min-h-[300px] overflow-y-auto"
      removeScroll
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Action Files
      </Typography>

      {isLoading && (
        <Spinner
          withBackdrop
          fallbackText={isDeleting ? 'Deleting the file...' : 'Loading...'}
        />
      )}

      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-8 w-full max-h-[60vh] min-h-24 lg:max-h-[50vh] overflow-y-auto p-1">
          {actionFiles.length ? (
            <ul>
              {actionFiles.map((file) => {
                const nameOfFile = getFileName(file.fileName, actionId);

                return (
                  <li
                    key={file.id}
                    className="flex gap-2 items-center"
                  >
                    <div className="size-1.5 rounded-full bg-textColor-primary" />

                    <a
                      href={file.fileUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-brandingColor-primary-gradient font-semibold"
                    >
                      {nameOfFile}
                    </a>

                    <Button
                      onClick={() => deleteFile(file.id)}
                      className="text-textColor-danger"
                    >
                      Delete
                    </Button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Typography
              variant="p1"
              fontWeight="semibold"
            >
              Files list is empty
            </Typography>
          )}

          <InputFileUpload
            handleFileChange={handleFileChange}
            files={values.files || []}
            disabled={false}
            multiple={false}
          />
        </div>

        <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={toggleModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            size="lg"
            endIcon={
              <Icons.Outlined.Edit.CheckmarkIcon className="fill-white pointer-events-none" />
            }
            autoFocus
            disabled={!values.files.length || isLoadingNewDoc}
          >
            Add File
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { ActionFilesModal };
