import { useSelector } from 'react-redux';

import { Dashboard } from 'src/features/admin/Dashboard';
import {
  useGetC2OAdminMetricsQuery,
  useGetSurveyAnswersStatsByTypeQuery,
  useGetInspectorStatsBySurveyAnswersQuery,
  useGetTopProjectsStatsBySurveyAnswersQuery,
  useGetTopAssignmentsStatsBySurveyAnswersQuery,
} from 'src/store/api';
import {
  ChartComponentName,
  DEFAULT_COLOR_BAR,
  SECONDARY_COLOR_BAR,
} from 'src/features/admin/Dashboard/helpers/constants';
import { selectCurrentUser } from 'src/store/slices';
import { Role } from 'src/shared/types';
import { ALL_ROLES } from 'src/shared/constants';

import { checkUserRoleAccess } from 'src/shared/c2o/utils/functions';

const C2ODashboard = () => {
  const user = useSelector(selectCurrentUser);
  const userRole = user?.ProviderRoleMatrix?.userRole as Role;

  const hasAccess = checkUserRoleAccess({
    userRole,
    roles: ALL_ROLES,
    forbiddenRoles: [],
  });

  const { data: metrics = [], isLoading: isLoadingMetrics } = useGetC2OAdminMetricsQuery('', {
    skip: !hasAccess,
  });

  const { data: inspectorStatsBySurveyAnswers, isLoading: isLoadingInspectorStatsBySurveyAnswers } =
    useGetInspectorStatsBySurveyAnswersQuery('', {
      skip: !hasAccess,
    });
  const { data: projectsStatsBySurveyAnswers, isLoading: isLoadingProjectsStatsBySurveyAnswers } =
    useGetTopProjectsStatsBySurveyAnswersQuery('', {
      skip: !hasAccess,
    });
  const {
    data: assignmentsStatsBySurveyAnswers,
    isLoading: isLoadingAssignmentsStatsBySurveyAnswers,
  } = useGetTopAssignmentsStatsBySurveyAnswersQuery('', {
    skip: !hasAccess,
  });
  const { data: surveyAnswersStatsByType, isLoading: isLoadingSurveyAnswersStatsByType } =
    useGetSurveyAnswersStatsByTypeQuery('', {
      skip: !hasAccess,
    });

  const isLoadingData =
    isLoadingMetrics ||
    isLoadingInspectorStatsBySurveyAnswers ||
    isLoadingProjectsStatsBySurveyAnswers ||
    isLoadingAssignmentsStatsBySurveyAnswers ||
    isLoadingSurveyAnswersStatsByType;

  return (
    <div className="w-full h-full p-4 max-h-screen overflow-y-scroll">
      <Dashboard
        metrics={metrics}
        chartViewList={[
          {
            data: projectsStatsBySurveyAnswers,
            componentName: ChartComponentName.BarChartView,
            defaultColor: DEFAULT_COLOR_BAR,
            layout: 'vertical',
          },
          {
            data: assignmentsStatsBySurveyAnswers,
            componentName: ChartComponentName.BarChartView,
            defaultColor: SECONDARY_COLOR_BAR,
            layout: 'vertical',
          },
          {
            data: inspectorStatsBySurveyAnswers,
            componentName: ChartComponentName.PieChartView,
          },
          {
            data: surveyAnswersStatsByType,
            componentName: ChartComponentName.BarChartView,
            layout: 'horizontal',
          },
        ]}
        isLoadingData={isLoadingData}
      />
    </div>
  );
};

export { C2ODashboard };
