import clsx from 'clsx';
import { FC, useId } from 'react';

interface TextAreaInputProps {
  label?: string;
  placeholder?: string;
  name?: string;
  value: string;
  onChange: (value: string) => void;
  textAreaClassName?: string;
  textLabelClassName?: string;
  isRequired?: boolean;
  error?: string;
}

const TextAreaInput: FC<TextAreaInputProps> = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  textAreaClassName,
  textLabelClassName,
  isRequired = false,
  error,
}) => {
  const textAreaId = useId();

  return (
    <div className="flex flex-col gap-y-2">
      {label && (
        <label
          htmlFor={textAreaId}
          className={clsx('text-base font-bold text-textColor-primary', textLabelClassName)}
        >
          {label}

          {isRequired && <span className="text-textColor-danger">*</span>}
        </label>
      )}

      <textarea
        name={name || placeholder}
        id={textAreaId}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className={clsx(
          'resize-none w-full h-[150px] rounded-xl border border-[#E4E9F2] px-4 py-3 bg-[rgba(99,155,221,0.06)]',
          textAreaClassName,
        )}
        required={isRequired}
      />

      {error && <p className="text-semanticColor-danger text-sm leading-normal">{error}</p>}
    </div>
  );
};

export { TextAreaInput };
