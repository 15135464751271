import { FC, PropsWithChildren, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { match } from 'ts-pattern';
import clsx from 'clsx';

import { RootState, useAppDispatch } from 'src/store';
import { IconButton } from 'src/shared/ui/iconButton';
import { ReactComponent as ArrowIOSLeftIcon } from 'src/assets/icons/filled/chevrons/arrow-ios-left.svg';
import { ReactComponent as ArrowIOSRightIcon } from 'src/assets/icons/filled/chevrons/arrow-ios-right.svg';
import {
  dayjs,
  getCurrentDay,
  getDaysHandler,
  getFirstDayOfWeek,
  getPaginationDays,
  getRangeOfDatesBasedOnLayout,
  not,
} from 'src/shared/utils';
import { calendarActions } from 'src/store/slices/calendar';
import { configActions } from 'src/store/slices/config';
import { modalConfigActions } from 'src/store/slices/modalConfig';

import { Datepicker } from '../datepicker';
import { TimePeriod } from 'src/shared/types';
import { truncate } from 'fs/promises';

type DateRangeSelectorProps = PropsWithChildren & {
  isActivityPage?: boolean;
};

const DateRangeSelector: FC<DateRangeSelectorProps> = ({ isActivityPage }) => {
  const dispatch = useAppDispatch();
  const selectedDate = useSelector((state: RootState) => state.calendar.selectedDate);
  const selectedRange = useSelector((state: RootState) => state.calendar.currentLayout);
  const dates = useSelector((state: RootState) => state.calendar.dates);

  const currentDay = getCurrentDay();
  const firstDayOfWeek = getFirstDayOfWeek(currentDay);
  const startDate = dates.length ? dayjs([...dates].shift()).format('MMMM DD') : null;
  const endDate = dates.length ? dayjs([...dates].pop()).format('MMMM DD') : null;

  useLayoutEffect(() => {
    if (not(selectedDate)) {
      dispatch(calendarActions.changeSelectedDate({ date: firstDayOfWeek }));
      dispatch(
        calendarActions.changeDates({
          dates: getRangeOfDatesBasedOnLayout(firstDayOfWeek)(selectedRange),
        }),
      );
    }
  });

  const handleDateChange = (direction: 'back' | 'forward') => () => {
    const manipulateDateRange =
      selectedRange === '3days' || selectedRange === 'custom' ? TimePeriod.Day : selectedRange;
    const manipulateCount = selectedRange === '3days' ? 3 : 1;

    const dates = match(direction)
      .with('back', () =>
        getDaysHandler(selectedRange)(
          dayjs(selectedDate).subtract(manipulateCount, manipulateDateRange).format(),
        ),
      )
      .with('forward', () =>
        getDaysHandler(selectedRange)(
          dayjs(selectedDate).add(manipulateCount, manipulateDateRange).format(),
        ),
      )
      .exhaustive();

    dispatch(calendarActions.changeDates({ dates }));
    dispatch(calendarActions.changeSelectedDate({ date: dates[0] }));

    const [startDate, endDate] = getPaginationDays(dates);

    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
    dispatch(configActions.setSelectedWeek({ selectedWeek: `${startDate} - ${endDate}` }));
  };

  const asCustom = selectedRange === 'custom';

  return (
    <div className="flex gap-x-2 items-center">
      <IconButton
        color={isActivityPage ? 'basic' : 'primary'}
        onClick={handleDateChange('back')}
        size="none"
        className={clsx('h-[32px] w-[32px]', isActivityPage && 'bg-transparent')}
        disabled={asCustom}
      >
        <ArrowIOSLeftIcon />
      </IconButton>

      <Datepicker
        useRange={false}
        placeholder="Select your date"
        value={{
          startDate,
          endDate,
        }}
        asWeek={!asCustom && selectedRange !== 'day'}
        asSingle={selectedRange === 'day'}
        as3Days={selectedRange === '3days'}
        asCustom={asCustom}
        displayFormat="DD MMMM"
        separator="-"
        onChange={(el) => {
          if (!el) return;
          dispatch(
            calendarActions.changeDates({
              dates: getRangeOfDatesBasedOnLayout(
                String(el.startDate),
                String(el.endDate),
              )(selectedRange),
            }),
          );

          dispatch(calendarActions.changeSelectedDate({ date: dayjs(el.startDate).format() }));
        }}
        inputClassName={isActivityPage ? 'text-brandingColor-primary-gradient' : 'text-[#fff]'}
        containerClassName="w-auto md:w-[350px]"
        isBorderPrimary={isActivityPage}
      />

      <IconButton
        color={isActivityPage ? 'basic' : 'primary'}
        onClick={handleDateChange('forward')}
        size="none"
        className={clsx('h-[32px] w-[32px]', isActivityPage && 'bg-transparent')}
        disabled={asCustom}
      >
        <ArrowIOSRightIcon />
      </IconButton>
    </div>
  );
};

export { DateRangeSelector };
export type { DateRangeSelectorProps };
