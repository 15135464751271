import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { FC, useEffect, useMemo, useState } from 'react';

import { Card } from 'src/shared/ui/card';
import { useGetJELLItemsQuery, useLazyGetJELLDetailsQuery } from 'src/store/api';
import { Icon } from 'src/shared/ui/icon';
import { ReactComponent as CarIcon } from 'src/assets/icons/outlined/shop/car.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/outlined/controls/filter.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/outlined/edit/edit.svg';
import { ReactComponent as CheckmarkCircleIcon } from 'src/assets/icons/outlined/edit/checkmark-circle.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/outlined/edit/trash.svg';
import { Typography } from 'src/shared/ui/typography';
import { IconButton } from 'src/shared/ui/iconButton';
import { Switch } from 'src/shared/ui/switch';
import { Table, TableHeaderType } from 'src/shared/ui/table';
import { EquipmentJELL, JobEntity, Role, TicketEntity } from 'src/shared/types';
import { selectModalConfig, selectCurrentUser } from 'src/store/slices';
import { clsx, not } from 'src/shared/utils';
import { DetailsSkeleton } from 'src/shared/ui/skeleton/ui/detailsSkeleton';

import { CreateAdditionalItemsModal, DeleteItemModal, EditItemModal } from './components';

type RequiredEquipmentTableProps = {
  ticket: TicketEntity;
  job: JobEntity;
  withActionButtons?: boolean;
};

const RequiredEquipmentTable: FC<RequiredEquipmentTableProps> = ({
  ticket,
  job,
  withActionButtons,
}) => {
  const [
    getJELLDetails,
    {
      data = {
        requiredEquipment: [],
        requiredLabor: [],
      },
      isLoading,
    },
  ] = useLazyGetJELLDetailsQuery();

  const {
    data: JELLItems = {
      requiredEquipment: [],
      requiredLabor: [],
    },
  } = useGetJELLItemsQuery(ticket?.JELL?.EquipmentKitID || '', {
    skip: not(ticket?.JELL?.EquipmentKitID),
  });

  const user = useSelector(selectCurrentUser);
  const modalConfig = useSelector(selectModalConfig);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [openModalId, setOpenModalId] = useState<'create' | 'edit' | 'delete' | null>(null);
  const [selectedItemId, setSelectedItemId] = useState('');

  const { requiredEquipment } = data;
  const equipmentJELLItems = JELLItems.requiredEquipment;

  const isCreateModalOpen =
    openModalId === 'create' || modalConfig.openModalId === 'CreateEquipment';
  const isEditModalOpen = openModalId === 'edit';
  const isDeleteModalOpen = openModalId === 'delete';

  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;
  const isNotReadonly = not(isReadonly);

  const filteredRequiredEquipment = isFilterActive
    ? requiredEquipment
    : requiredEquipment.filter((el) => {
        if (el?.ContractRateSheet?.ItemCodeCategoryID === '3') {
          return true;
        }

        if (el?.ContractRateSheet?.ItemCodeCategoryID === '4' && el?.RequiresUnitNumber === 'Yes') {
          return true;
        }

        return false;
      });

  const requiredEquipmentHeaders: TableHeaderType<EquipmentJELL>[] = useMemo(
    () => [
      {
        title: 'Filled',
        field: 'Filled',
        render: (el) =>
          (el.UnitNumber || el.RentalUnitNumber) && (
            <Icon
              size="sm"
              icon={<CheckmarkCircleIcon />}
              className="fill-semanticColor-success mx-auto my-0"
            />
          ),
        className: 'w-[50px]',
      },
      {
        title: 'Item Code',
        field: 'ItemCode',
        render: (el) => el?.ContractRateSheet?.Itemcode,
        className: 'w-[95px]',
      },
      {
        title: 'Description',
        field: 'Description',
        render: (el) => el?.ContractRateSheet?.CustomItemDescription,
        className: 'w-[150px]',
      },
      {
        title: 'Category',
        field: 'Category',
        render: (el) => el?.ContractRateSheet?.ItemCodeCategoryName,
        className: 'w-[100px]',
      },
      {
        title: 'UOM',
        field: 'UOM',
      },
      {
        title: 'QTY',
        field: 'QTY',
      },
      {
        title: 'Req. Unit #',
        field: 'RequiresUnitNumber',
        render: (el) => {
          if (el.IsRental && el.RequiresUnitNumber) {
            return 'RENTAL';
          }

          if (el.UnitNumber) {
            return `#${el.UnitNumber}`;
          }

          return el.RequiresUnitNumber;
        },
      },
      {
        title: 'JELL',
        field: 'JELL',
        render: (el) => {
          const isFromJEL = equipmentJELLItems.some(
            (item) => item?.ContractRateSheet?.Itemcode === el?.ContractRateSheet?.Itemcode,
          );

          return isFromJEL ? 'Yes' : 'No';
        },
      },
      {
        title: 'Actions',
        field: 'Actions',
        className: 'w-[70px]',
        render: (el) => {
          const isFromJEL = equipmentJELLItems.some(
            (item) => item?.ContractRateSheet?.Itemcode === el?.ContractRateSheet?.Itemcode,
          );
          const withEditButton =
            not(isFromJEL) &&
            not(el.UnitNumber) &&
            not(el.RentalUnitNumber) &&
            el.RequiresUnitNumber === 'No';
          const withTrashButton = not(isFromJEL) && not(el.UnitNumber) && not(el.RentalUnitNumber);

          return (
            isNotReadonly &&
            withActionButtons && (
              <div className="flex gap-1">
                {withEditButton && (
                  <IconButton size="sm">
                    <EditIcon
                      onClick={() => {
                        setOpenModalId('edit');
                        setSelectedItemId(el.KITItemsRecordID);
                      }}
                    />
                  </IconButton>
                )}

                {withTrashButton && (
                  <IconButton size="sm">
                    <TrashIcon
                      onClick={() => {
                        setOpenModalId('delete');
                        setSelectedItemId(el.KITItemsRecordID);
                      }}
                    />
                  </IconButton>
                )}
              </div>
            )
          );
        },
      },
    ],
    [equipmentJELLItems, isNotReadonly, withActionButtons],
  );

  const equipmentBlock = requiredEquipment.length ? (
    <>
      <div className="flex justify-between">
        <div className="flex justify-between items-center gap-2">
          <div className="w-6 h-6">
            <Icon
              icon={<CarIcon />}
              className="fill-brandingColor-primary-gradient w-6 h-6"
            />
          </div>

          <Typography
            variant="p1"
            fontWeight="bold"
          >
            Equipment
          </Typography>
        </div>

        <div className="flex gap-5 items-center">
          {isNotReadonly && withActionButtons && (
            <IconButton
              color="basic"
              iconSize="md"
              iconClassName="fill-textColor-tertiary"
              onClick={() => setOpenModalId('create')}
            >
              <PlusIcon />
            </IconButton>
          )}

          <Switch
            checked={isFilterActive}
            value={Number(isFilterActive)}
            onClick={() => setIsFilterActive(!isFilterActive)}
            endLabel="Show all"
            startIcon={<FilterIcon />}
            startIconClassName={clsx(
              'p-1 w-[28px] h-[28px] !fill-textColor-tertiary',
              !Number(isFilterActive) &&
                '!fill-brandingColor-primary-gradient rounded-[50%] bg-textColor-light',
            )}
          />
        </div>
      </div>

      <Table
        headers={requiredEquipmentHeaders}
        data={filteredRequiredEquipment}
        scroll
        containerClassName="max-h-[225px]"
        idProvider={(el: EquipmentJELL) =>
          `${
            (el?.ContractRateSheet?.Itemcode || '') +
            (el?.ContractRateSheet?.CustomItemDescription || '') +
            uuidv4()
          }`
        }
      />
    </>
  ) : (
    <div className="flex justify-between items-center gap-3">
      <Typography variant="p1">Required Equipment was not found</Typography>

      {isNotReadonly && withActionButtons && (
        <IconButton
          color="basic"
          iconSize="md"
          iconClassName="fill-textColor-tertiary"
          onClick={() => setOpenModalId('create')}
        >
          <PlusIcon />
        </IconButton>
      )}
    </div>
  );

  useEffect(() => {
    getJELLDetails(ticket.id as string);
  }, [getJELLDetails, ticket]);

  if (isLoading) {
    return <DetailsSkeleton variant="requirements" />;
  }

  return (
    <>
      <Card accordionTitle={equipmentBlock} />

      {isCreateModalOpen && (
        <CreateAdditionalItemsModal
          isOpen={isCreateModalOpen}
          setIsOpen={setOpenModalId}
          rateSheeetId={job.ownerContract.RateSheetID}
          ticket={ticket}
          type="Equipment"
        />
      )}

      {isDeleteModalOpen && (
        <DeleteItemModal
          ticket={ticket}
          isOpen={isDeleteModalOpen}
          setIsOpen={setOpenModalId}
          selectedItemId={selectedItemId}
          type="Equipment"
        />
      )}

      {isEditModalOpen && (
        <EditItemModal
          ticket={ticket}
          isOpen={isEditModalOpen}
          setIsOpen={setOpenModalId}
          selectedItemId={selectedItemId}
          type="Equipment"
        />
      )}
    </>
  );
};

export { RequiredEquipmentTable };
