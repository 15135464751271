import { FC } from 'react';
import { match } from 'ts-pattern';

import { clsx } from 'src/shared/utils/clsx';
import { Skeleton } from 'src/shared/ui/skeleton';

import { Size } from '../../button/button.types';

type BtnSkeletonProps = {
  className?: string;
  size?: Size;
};
const BtnSkeleton: FC<BtnSkeletonProps> = ({ className, size = 'md', ...props }) => {
  const sizeClasses = match(size)
    .with('sm', () => 'h-[32px] py-[8px] px-[8px] min-w-[83px] rounded-lg')
    .with('md', () => 'h-[40px] py-[10px] px-[8px] min-w-[95px] rounded-xl gap-x-[8px]')
    .with('lg', () => 'h-[48px] py-[12px] px-[12px] min-w-[108px] rounded-lg gap-x-[4px]')
    .exhaustive();

  const classes = clsx(sizeClasses, className);

  return (
    <Skeleton
      className={classes}
      {...props}
    />
  );
};

export { BtnSkeleton };
