/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
import { FC, useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { v4 as uuidv4 } from 'uuid';

import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { TextField } from 'src/shared/ui/textField';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/outlined/misc/clock.svg';
import { ReactComponent as ChevronRightIcon } from 'src/assets/icons/outlined/chevrons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from 'src/assets/icons/outlined/chevrons/chevron-left.svg';
import { ReactComponent as CheckmarkIcon } from 'src/assets/icons/filled/edit/checkmark.svg';
import { clsx } from 'src/shared/utils/clsx';
import { IconButton } from 'src/shared/ui/iconButton';
import { Datepicker } from 'src/shared/ui/datepicker';
import {
  dayjs,
  formatDate,
  formatTicketDate,
  getDatesBetween,
  getDurationValue,
  getEndTimeOptions,
  getFormattedTime,
  getFormattedTimePickerDateTime,
  getFormattedTimePickerTime,
  getStartTimeOptions,
  getTimePickerOptions,
  showToastErrorMessage,
} from 'src/shared/utils';
import { JobEntity } from 'src/shared/types';
import {
  useGetOwnerLocationsQuery,
  useGetOwnersQuery,
  useGetServiceLinesQuery,
  useAddJobMutation,
  useUpdateJobMutation,
  useGetProviderDivisionsQuery,
  useGetJobJELLQuery,
  useGetContractListsQuery,
  useGetJobCategoriesQuery,
} from 'src/store/api';
import { useAppDispatch } from 'src/store';
import { configActions } from 'src/store/slices';

import { SelectInput, SelectInputItem } from '../../selectInput';
import { Checkbox } from '../../checkbox';
import { TimePickerInput } from '../../timePickerInput';
import { EndsTomorrowIndicator } from '../../endsTomorrowIndicator';
import { CheckboxItem, Checkboxes } from '../../checkboxes/checkboxes';
import { AddNewContact } from '../addNewContact';

import { JobFormInitialValues, Step } from './types';
import { isLunchAllowedOptions, jobFormInitialValues, shiftTypes } from './constants';

type CreateOrUpdateJobModalProps<T = 'create' | 'update'> = T extends 'create'
  ? {
      isOpen: boolean;
      setIsOpen: (isOpen: boolean) => void;
      type: T;
      job?: JobEntity;
    }
  : {
      isOpen: boolean;
      setIsOpen: (isOpen: boolean) => void;
      type: T;
      job: JobEntity;
    };

const CreateOrUpdateJobModal: FC<CreateOrUpdateJobModalProps> = ({
  isOpen = false,
  setIsOpen,
  type,
  job,
}) => {
  const dispatch = useAppDispatch();

  const { data: owners = [], refetch } = useGetOwnersQuery('');
  const { data: ownerLocations = [] } = useGetOwnerLocationsQuery('');
  const { data: providerDivisions = [] } = useGetProviderDivisionsQuery('');
  const { data: serviceLines = [] } = useGetServiceLinesQuery('');
  const { data: JobJELLItems = [] } = useGetJobJELLQuery('');
  const { data: contractLists = [] } = useGetContractListsQuery('');
  const { data: jobCategories = [] } = useGetJobCategoriesQuery('');

  const [addJob] = useAddJobMutation();
  const [updateJob] = useUpdateJobMutation();
  const [selectedStep, setSelectedStep] = useState<Step>(Step.owner);

  const [isAddNewContactModalOpen, setIsAddNewContactModalOpen] = useState(false);

  const toggleContactModal = () => {
    setIsAddNewContactModalOpen((prev) => !prev);
  };

  const isOwnerStepOpen = selectedStep === Step.owner;
  const isDetailsStepOpen = selectedStep === Step.details;
  const isScheduleStepOpen = selectedStep === Step.schedule;

  const ticketsForOrder = job?.tickets ?? [];

  const toggleModal = (isOpen: boolean) => {
    if (isAddNewContactModalOpen) {
      return;
    }

    setIsOpen(!isOpen);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetForm();
    setSelectedStep(Step.owner);
  };

  const onSubmit = async (values: JobFormInitialValues) => {
    toggleModal(isOpen);

    const { dateRange, description, isLunchAllowed, po, wo, isJELEnforced, sro } = values;

    const owner = owners.find((owner) => owner.OwnerName === values.owner);
    const ownerLocation = ownerLocations.find(
      (ownerLocation) => ownerLocation.OwnerLocation === values.ownerLocation,
    );
    const serviceLine = serviceLines.find(
      (serviceLine) => serviceLine.ServiceLineDescription === values.serviceLine,
    );
    const serviceType = serviceLines
      .find((serviceLine) => serviceLine.ServiceLineDescription === values.serviceLine)
      ?.ServiceTypes.find(
        (serviceType) => serviceType.ServiceTypeDescription === values.serviceType,
      );
    const ownerContract = contractLists.find(
      (contract) => contract.RateSheetDescription === values.ownerContract,
    );
    const ownerContact = owners
      .find((owner) => owner.OwnerName === values.owner)
      ?.OwnerPersonnel.find(
        (ownerPersonnel) =>
          `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}` === values.ownerContact,
      );
    const ownerRepresentative = owners
      .find((owner) => owner.OwnerName === values.owner)
      ?.OwnerPersonnel.find(
        (ownerPersonnel) =>
          `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}` === values.ownerRepresentative,
      );
    const department = providerDivisions.find(
      (providerDivision) => providerDivision.ProviderDivisionName === values.department,
    );

    const JELL = JobJELLItems.find((JELL) => JELL.AdditionalKitDescription1 === values.JELL);

    const jobCategory = jobCategories.find(
      (jobCategory) => jobCategory.Category === values.jobCategory,
    );

    const timezone = ownerLocation?.LocationTimezone;

    const jobOptions = {
      jobIdentifier: description,
      requestedStartDate: dayjs(dateRange?.startDate).hour(12).utc().format(),
      requestedCompleteDate: dayjs(dateRange?.endDate).hour(12).utc().format(),
      projectName: `${values.ownerLocation}/${values.serviceLine}/${values.description}`,
      workRequestNotes: description,
      serviceLine,
      serviceType,
      owner,
      ownerLocation,
      ownerContract,
      ownerContact,
      ownerRepresentative,
      department,
      jobCategory,
      isLunchAllowed,
      po,
      wo,
      JELL,
      isJELEnforced: values.JELL ? isJELEnforced : false,
      defaultScheduledStartTime: getTimePickerValue({
        value: defaultScheduledStartTime,
        isDateTime: true,
        timezone,
      }),
      defaultScheduledDuration: getTimePickerValue({ value: defaultScheduledDuration }),
      defaultScheduledTimeOnYard: getTimePickerValue({
        value: defaultScheduledTimeOnYard,
        isDateTime: true,
        timezone,
      }),
      defaultScheduledBaseTime: getTimePickerValue({
        value: defaultScheduledBaseTime,
        isDateTime: true,
        timezone,
      }),
      sro,
    };

    const jobId = uuidv4();

    if (type === 'create') {
      try {
        dispatch(configActions.addLoadingEntityId(jobId));

        await addJob({
          id: jobId,
          tickets: [],
          row: 1,
          timezone,
          ...jobOptions,
        }).unwrap();
      } catch {
        showToastErrorMessage(
          `Sorry, an error occurred, when you tried to create a Job, please check your internet connection`,
        );
      } finally {
        dispatch(configActions.removeLoadingEntityId(jobId));
      }
    } else {
      try {
        await updateJob({
          timezone,
          ...job,
          ...jobOptions,
        }).unwrap();
      } catch {
        showToastErrorMessage(
          `Sorry, an error occurred, when you tried to update a Job, please check your internet connection`,
        );
      }
    }
  };

  const getTimePickerValue = ({
    value,
    isDateTime,
    usedForDuration,
    timezone,
  }: {
    value: string;
    isDateTime?: boolean;
    usedForDuration?: boolean;
    timezone?: string;
  }) => {
    if (!value) return undefined;

    const [hours, minutes] = usedForDuration
      ? value.split(':').map(Number)
      : value.split(' ')[0].split(':').map(Number);

    if (isDateTime && timezone) {
      return dayjs().tz(timezone).hour(hours).minute(minutes).second(0).format();
    }

    return dayjs().utc().hour(hours).minute(minutes).second(0).format();
  };

  const ticketsDates = ticketsForOrder
    .map((ticket) => formatTicketDate(ticket.startTime))
    .sort((a, b) => {
      if (dayjs(a).isAfter(dayjs(b))) return 1;
      if (dayjs(a).isBefore(dayjs(b))) return -1;
      return 0;
    });

  const Schema = z.object({
    owner: z.string().nonempty(),
    ownerContract: z.string().nonempty(),
    department: z.string().nonempty(),
    serviceLine: z.string().nonempty(),
    description: z.string().nonempty(),
    dateRange: z.object({
      startDate: z.custom(
        (value) => {
          const ticketDateBeforeOrder = ticketsDates.find((date) =>
            dayjs(date).isBefore(String(value), 'day'),
          );

          return !ticketDateBeforeOrder;
        },
        () => {
          const earliestTicket = ticketsDates.shift();
          return {
            message: `Entered date range doesn't include existing tickets dates (from ${dayjs(
              earliestTicket,
            )?.format('MMM D')}). Please remove or move tickets before adjusting the date range.`,
          };
        },
      ),
      endDate: z.custom(
        (value) => {
          const ticketDateAfterOrder = ticketsDates.find((date) =>
            dayjs(date).isAfter(String(value), 'day'),
          );
          return !ticketDateAfterOrder;
        },
        () => {
          const latestTicket = ticketsDates.pop();

          return {
            message: `Entered date range doesn't include existing tickets dates (up to ${dayjs(
              latestTicket,
            )?.format('MMM D')}). Please remove or move tickets before adjusting the date range.`,
          };
        },
      ),
    }),
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
    setValues,
  } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(Schema),
    initialValues:
      type === 'create'
        ? jobFormInitialValues
        : {
            dateRange: {
              startDate: job?.requestedStartDate
                ? formatDate(String(job.requestedStartDate).split('T')[0])
                : '',
              endDate: job?.requestedCompleteDate
                ? formatDate(String(job.requestedCompleteDate).split('T')[0])
                : '',
            },
            owner: job?.owner?.OwnerName || '',
            ownerContract: job?.ownerContract?.RateSheetDescription || '',
            ownerContact:
              (job?.ownerContact &&
                `${job?.ownerContact?.FirstName} ${job?.ownerContact?.LastName}`) ||
              '',
            ownerRepresentative:
              (job?.ownerRepresentative &&
                `${job?.ownerRepresentative?.FirstName} ${job?.ownerRepresentative?.LastName}`) ||
              '',
            ownerLocation: job?.ownerLocation?.OwnerLocation || '',
            po: job.po || '',
            wo: job.wo || '',
            department: job?.department?.ProviderDivisionName || '',
            serviceLine: job?.serviceLine?.ServiceLineDescription || '',
            serviceType: job?.serviceType?.ServiceTypeDescription || '',
            description: job.workRequestNotes || '',
            jobCategory: job?.jobCategory?.Category || '',
            jobIdentifier: '',
            isLunchAllowed: job?.isLunchAllowed || 'No',
            shiftType: 'Day',
            JELL: job?.JELL?.AdditionalKitDescription1 || '',
            isJELEnforced: job?.isJELLEnforced || false,
            defaultScheduledStartTime: job.defaultScheduledStartTime
              ? getFormattedTimePickerDateTime(job.defaultScheduledStartTime)
              : '',
            defaultScheduledDuration: job.defaultScheduledDuration
              ? getFormattedTimePickerTime(job.defaultScheduledDuration, true)
              : '',
            defaultScheduledTimeOnYard: job.defaultScheduledTimeOnYard
              ? getFormattedTimePickerDateTime(job.defaultScheduledTimeOnYard)
              : '',
            defaultScheduledBaseTime: job.defaultScheduledBaseTime
              ? getFormattedTimePickerDateTime(job.defaultScheduledBaseTime)
              : '',
            sro: job.sro || '',
          },
    enableReinitialize: true,
  });

  const owner = owners.find((owner) => owner.OwnerName === values.owner);

  const addNewItemButtonValues = useMemo(
    () => ({
      onClick: () => setIsAddNewContactModalOpen(true),
      buttonTitle: 'Add New Contact',
    }),
    [],
  );

  const ownerContract = contractLists.find(
    (contract) => contract.RateSheetDescription === values.ownerContract,
  );

  const selectedDays = getDatesBetween(values.dateRange);
  const totalDays = selectedDays.length;

  const defaultScheduledTimeOnYard = getFormattedTime(values.defaultScheduledTimeOnYard);
  const defaultScheduledBaseTime = getFormattedTime(values.defaultScheduledBaseTime);

  const [endTimeValue, setEndTimeValue] = useState('');

  const defaultScheduledStartTime = useMemo(
    () => getFormattedTime(values.defaultScheduledStartTime),
    [values.defaultScheduledStartTime],
  );
  const defaultScheduledDuration = useMemo(
    () => getFormattedTime(values.defaultScheduledDuration, getTimePickerOptions(true)),
    [values.defaultScheduledDuration],
  );

  const { endTimeEndsTomorrow } = useMemo(
    () => getEndTimeOptions(defaultScheduledStartTime, defaultScheduledDuration),
    [defaultScheduledStartTime, defaultScheduledDuration],
  );

  const handleStartTimeSelect = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
      const formatStart = getFormattedTime(value);

      if (formatStart && defaultScheduledDuration) {
        const { endTime } = getEndTimeOptions(formatStart, defaultScheduledDuration);

        if (endTime !== endTimeValue) {
          setEndTimeValue(endTime);
        }
      }

      if (formatStart && !defaultScheduledDuration && endTimeValue) {
        const { duration } = getDurationValue(endTimeValue, formatStart);
        setFieldValue('defaultScheduledDuration', duration.replace(':', ''));
      }
    },
    [defaultScheduledDuration, endTimeValue, setFieldValue],
  );

  const handleDurationSelect = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);

      const duration = getFormattedTime(value, getTimePickerOptions(true));

      if (defaultScheduledStartTime && duration) {
        const { endTime } = getEndTimeOptions(defaultScheduledStartTime, duration);

        if (endTime !== endTimeValue) {
          setEndTimeValue(endTime);
        }
      }

      if (endTimeValue && duration && !defaultScheduledStartTime) {
        const { startTime, isStartTimeYesterday } = getStartTimeOptions(endTimeValue, duration);

        if (!isStartTimeYesterday) {
          setFieldValue('defaultScheduledStartTime', startTime.replace(':', ''));
        }
      }
    },
    [defaultScheduledStartTime, endTimeValue, setFieldValue],
  );

  const handleEndTimeSelect = useCallback(
    (name: string, value: string) => {
      const formatEnd = getFormattedTime(value);

      setEndTimeValue(formatEnd);

      if (formatEnd && defaultScheduledDuration) {
        const { startTime, isStartTimeYesterday } = getStartTimeOptions(
          formatEnd,
          defaultScheduledDuration,
        );

        if (!isStartTimeYesterday) {
          setFieldValue('defaultScheduledStartTime', startTime.replace(':', ''));
        } else {
          setFieldValue('defaultScheduledStartTime', '');
        }
      }

      if (defaultScheduledStartTime && !defaultScheduledDuration && formatEnd) {
        const { duration } = getDurationValue(formatEnd, defaultScheduledStartTime);
        setFieldValue('defaultScheduledDuration', duration);
      }
    },
    [defaultScheduledDuration, defaultScheduledStartTime, setFieldValue],
  );

  const isDetailsStepDisabled = Boolean(
    values.owner === '' || values.ownerContract === '' || values.ownerLocation === '',
  );

  const isScheduleStepDisabled = Boolean(
    values.jobCategory === '' ||
      values.department === '' ||
      values.serviceLine === '' ||
      values.description === '' ||
      isDetailsStepDisabled,
  );

  const isSaveDisabled = Boolean(
    !values.dateRange?.startDate ||
      !values.dateRange?.endDate ||
      errors.dateRange?.startDate ||
      errors.dateRange?.endDate ||
      isScheduleStepDisabled,
  );

  const isBackButtonVisible = isScheduleStepOpen || isDetailsStepOpen;

  const getIsPrimaryButtonDisabled = () => {
    if (isOwnerStepOpen) {
      return isDetailsStepDisabled;
    }

    if (isDetailsStepOpen) {
      return isScheduleStepDisabled;
    }

    return isSaveDisabled;
  };

  const changeStep = (step: string) => {
    if (selectedStep === Step.owner && !isDetailsStepDisabled && step === Step.details) {
      setSelectedStep(step);
    }

    if (selectedStep === Step.details && !isScheduleStepDisabled && step === Step.schedule) {
      setSelectedStep(step);
    }

    if (selectedStep === Step.details && step === Step.owner) {
      setSelectedStep(step);
    }

    if (selectedStep === Step.schedule && (step === Step.details || step === Step.owner)) {
      setSelectedStep(step);
    }

    if (selectedStep === Step.owner && !isScheduleStepDisabled && step === Step.schedule) {
      setSelectedStep(step);
    }
  };

  const clearDateRange = () => {
    setFieldValue('dateRange', {
      startDate: null,
      endDate: null,
    });
  };

  const currentStep = (
    <div className="flex justify-between gap-6">
      {Object.values(Step).map((step, index) => (
        <button
          type="button"
          key={step}
          className="flex flex-col w-full text-left gap-3"
          onClick={() => changeStep(step)}
        >
          <Typography
            className={clsx(
              selectedStep === step ? 'text-brandingColor-primary-gradient' : 'text-textColor-hint',
            )}
            variant="h3"
          >
            {`${index + 1}. ${step}`}
          </Typography>

          <div
            className={clsx(
              'w-full h-1 rounded-[123px]',
              selectedStep === step ? 'bg-brandingColor-primary-gradient' : 'bg-textColor-hint',
            )}
          />
        </button>
      ))}
    </div>
  );

  const moveToPreviousStep = () => {
    setSelectedStep(selectedStep === Step.schedule ? Step.details : Step.owner);
  };

  const moveToNextStep = () => {
    setSelectedStep(selectedStep === Step.owner ? Step.details : Step.schedule);
  };

  const handlePrimaryButtonClick = () => {
    if (isScheduleStepOpen) {
      onSubmit(values);

      return;
    }

    moveToNextStep();
  };

  const buttons = (
    <div className={clsx('flex w-full', isBackButtonVisible ? 'justify-between' : 'justify-end')}>
      {isBackButtonVisible && (
        <Button
          type="button"
          variant="outlined"
          color="basic"
          size="lg"
          className="w-[108px]"
          startIcon={<ChevronLeftIcon />}
          iconClassName="fill-textColor-tertiary"
          onClick={() => moveToPreviousStep()}
        >
          Back
        </Button>
      )}

      <div className="flex justify-between gap-2">
        <Button
          type="button"
          variant="outlined"
          color="basic"
          size="lg"
          className="w-[108px]"
          onClick={() => toggleModal(isOpen)}
        >
          Cancel
        </Button>

        <Button
          type="button"
          color="primary"
          size="lg"
          className="w-[108px]"
          endIcon={isScheduleStepOpen ? <CheckmarkIcon /> : <ChevronRightIcon />}
          disabled={getIsPrimaryButtonDisabled()}
          onClick={() => handlePrimaryButtonClick()}
        >
          {isScheduleStepOpen ? 'Finish' : 'Next'}
        </Button>
      </div>
    </div>
  );

  const ownerStepContent = (
    <>
      <div className="flex flex-col justify-between gap-4 min-w-[868px]">
        <SelectInput
          isRequired
          name="owner"
          value={values.owner}
          label="Owner"
          placeholder="Select owner"
          onClear={(name) => setFieldValue(name, '')}
          items={owners.map((owner) => ({
            label: (
              <SelectInputItem selected={values.owner === owner.OwnerName}>
                {owner.OwnerName}
              </SelectInputItem>
            ),
            value: owner.OwnerName,
            onClick: () => {
              setValues(
                {
                  ...values,
                  owner: owner.OwnerName,
                  ownerContact: '',
                  ownerRepresentative: '',
                  ownerLocation: '',
                  ownerContract: '',
                  JELL: '',
                  isJELEnforced: false,
                },
                false,
              );
            },
          }))}
        />

        <SelectInput
          isRequired
          disabled={!values.owner}
          name="ownerLocation"
          value={values.ownerLocation}
          label="Location"
          placeholder="Select Owner Location"
          onClear={(name) => setFieldValue(name, '')}
          items={ownerLocations
            .filter(
              (ownerLocation) =>
                ownerLocation.OwnerID ===
                owners.find((owner) => owner.OwnerName === values.owner)?.OwnerID,
            )
            .map((ownerLocation) => ({
              label: (
                <SelectInputItem selected={values.ownerLocation === ownerLocation.OwnerLocation}>
                  {ownerLocation.OwnerLocation}
                </SelectInputItem>
              ),
              value: ownerLocation.OwnerLocation,
              onClick: () => {
                setFieldValue('ownerLocation', ownerLocation.OwnerLocation);
              },
            }))}
        />

        <SelectInput
          isRequired
          disabled={!values.ownerLocation}
          name="ownerContract"
          value={values.ownerContract}
          label="Rate Sheet"
          placeholder="Select Owner Rate Sheet"
          onClear={(name) => setFieldValue(name, '')}
          items={
            contractLists
              .filter((contract) => {
                if (
                  !contract.OwnerID &&
                  !contract.OwnerLocationID &&
                  contract?.MasterorCustomerContract?.toLowerCase().includes('master')
                ) {
                  return true;
                }

                if (owner) {
                  return contract.OwnerID === owner.OwnerID;
                }

                return true;
              })
              .map((contract) => ({
                label: (
                  <SelectInputItem
                    selected={values.ownerContract === contract.RateSheetDescription}
                  >
                    {contract.RateSheetDescription}
                  </SelectInputItem>
                ),
                value: contract.RateSheetDescription,
                onClick: () => {
                  setFieldValue('ownerContract', contract.RateSheetDescription);
                },
              })) ?? []
          }
        />

        <div className="flex justify-between gap-5">
          <SelectInput
            disabled={!values.owner}
            name="ownerContact"
            value={values.ownerContact}
            label="Contact"
            placeholder="Select Owner Contact"
            onClear={(name) => setFieldValue(name, '')}
            items={
              owners
                ?.find((owner) => owner?.OwnerName === values?.owner)
                ?.OwnerPersonnel.map((ownerPersonnel) => ({
                  label: (
                    <SelectInputItem
                      selected={
                        values.ownerContact ===
                        `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`
                      }
                    >
                      {`${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`}
                    </SelectInputItem>
                  ),
                  value: `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                  onClick: () => {
                    setFieldValue(
                      'ownerContact',
                      `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                    );
                  },
                })) ?? []
            }
            hasAddNewItemButton={addNewItemButtonValues}
          />

          <SelectInput
            disabled={!values.owner}
            name="ownerRepresentative"
            value={values.ownerRepresentative}
            label="Owner Representative (at work site)"
            placeholder="Select Owner Representative"
            onClear={(name) => setFieldValue(name, '')}
            items={
              owners
                ?.find((owner) => owner?.OwnerName === values?.owner)
                ?.OwnerPersonnel.map((ownerPersonnel) => ({
                  label: (
                    <SelectInputItem
                      selected={
                        values.ownerRepresentative ===
                        `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`
                      }
                    >
                      {`${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`}
                    </SelectInputItem>
                  ),
                  value: `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                  onClick: () => {
                    setFieldValue(
                      'ownerRepresentative',
                      `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                    );
                  },
                })) ?? []
            }
            hasAddNewItemButton={addNewItemButtonValues}
          />
        </div>
      </div>

      <div className="flex gap-4">
        <TextField
          value={values.po}
          name="po"
          onChange={handleChange}
          onBlur={handleBlur}
          label="P.O"
          placeholder="#12345"
          error={errors.po}
        />

        <TextField
          value={values.wo}
          name="wo"
          onChange={handleChange}
          onBlur={handleBlur}
          label="W.O"
          placeholder="#12345"
          error={errors.wo}
        />
      </div>
    </>
  );

  const detailsStepContent = (
    <>
      <SelectInput
        isRequired
        name="jobCategory"
        value={values.jobCategory}
        label="Job Category"
        placeholder="Select Job Category"
        onClear={(name) => setFieldValue(name, '')}
        items={jobCategories.map((option) => ({
          label: (
            <SelectInputItem selected={values.jobCategory === option.Category}>
              {option.Category}
            </SelectInputItem>
          ),
          value: option.Category,
          onClick: () => {
            setFieldValue('jobCategory', option.Category);
          },
        }))}
      />

      <div className="flex flex-col gap-4">
        <SelectInput
          isRequired
          name="department"
          value={values.department}
          label="Department"
          placeholder="Select department"
          onClear={(name) => setFieldValue(name, '')}
          items={providerDivisions.map((providerDivision) => ({
            label: (
              <SelectInputItem
                selected={values.department === providerDivision.ProviderDivisionName}
              >
                {providerDivision.ProviderDivisionName}
              </SelectInputItem>
            ),
            value: providerDivision.ProviderDivisionName,
            onClick: () => {
              setFieldValue('department', providerDivision.ProviderDivisionName);
            },
          }))}
        />

        <div className="flex justify-between gap-4">
          <SelectInput
            isRequired
            name="serviceLine"
            value={values.serviceLine}
            label="Service Line"
            placeholder="Select service line"
            onClear={(name) => setFieldValue(name, '')}
            items={serviceLines.map((serviceLine) => ({
              label: (
                <SelectInputItem
                  selected={values.serviceLine === serviceLine.ServiceLineDescription}
                >
                  {serviceLine.ServiceLineDescription}
                </SelectInputItem>
              ),
              value: serviceLine.ServiceLineDescription,
              onClick: () => {
                setValues(
                  {
                    ...values,
                    serviceLine: serviceLine.ServiceLineDescription,
                    serviceType: '',
                  },
                  false,
                );
              },
            }))}
          />

          <SelectInput
            disabled={!values.serviceLine}
            name="serviceType"
            value={values.serviceType}
            label="Service Type"
            placeholder="Select service type"
            onClear={(name) => setFieldValue(name, '')}
            items={
              serviceLines
                .find((serviceLine) => serviceLine.ServiceLineDescription === values.serviceLine)
                ?.ServiceTypes.map((serviceType) => ({
                  label: (
                    <SelectInputItem
                      selected={values.serviceType === serviceType.ServiceTypeDescription}
                    >
                      {serviceType.ServiceTypeDescription}
                    </SelectInputItem>
                  ),
                  value: serviceType.ServiceTypeDescription,
                  onClick: () => {
                    setFieldValue('serviceType', serviceType.ServiceTypeDescription);
                  },
                })) ?? []
            }
          />
        </div>

        <TextField
          value={values.description}
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          label="Description"
          placeholder="Type a description"
          isRequired
        />
      </div>

      <TextField
        value={values.sro}
        name="sro"
        onChange={handleChange}
        onBlur={handleBlur}
        label="Job Number"
        placeholder="Type a Job Number"
      />

      <SelectInput
        name="JELL"
        value={values.JELL}
        label="JELL"
        placeholder="Select JELL"
        onClear={(name) => setFieldValue(name, '')}
        items={
          JobJELLItems.filter((jell) => jell.Contract === ownerContract?.RateSheetID).map(
            (jell) => ({
              label: (
                <SelectInputItem selected={values.JELL === jell.AdditionalKitDescription1}>
                  {jell.AdditionalKitDescription1}
                </SelectInputItem>
              ),
              value: jell.AdditionalKitDescription1,
              onClick: () => {
                setFieldValue('JELL', jell.AdditionalKitDescription1);
              },
              customKey: jell.EquipmentKitID,
            }),
          ) ?? []
        }
      />

      <Checkbox
        labelClassName={clsx(!values.JELL && 'text-textColor-secondary')}
        endLabel="Enforce JELL"
        disabled={!values.JELL}
        checked={values.isJELEnforced}
        onClick={() => {
          setFieldValue('isJELEnforced', !values.isJELEnforced);
        }}
      />
    </>
  );

  const scheduleStepContent = (
    <div className="flex flex-col gap-[32px]">
      <div className="flex flex-col gap-4 pt-[26px] pb-4 px-3 border border-solid border-textColor-light rounded-lg">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Due Dates
          <span className="text-textColor-danger">*</span>
        </Typography>

        <div className="flex flex-col gap-y-2">
          <Typography variant="label">Dates of Job</Typography>

          <Datepicker
            asCustom
            error={errors.dateRange?.startDate || errors.dateRange?.endDate}
            useRange={false}
            placeholder="Select your date"
            value={values.dateRange}
            onChange={(value) => {
              setFieldValue('dateRange', value);
            }}
            displayFormat="dddd, DD MMMM"
            separator="-"
            toggleClassName={clsx(
              values.dateRange?.startDate && values.dateRange?.endDate
                ? 'absolute right-[12px] top-[12px] bg-transparent'
                : 'absolute left-[16px] top-[12px] bg-transparent',
            )}
            iconPosition="start"
            toggleIcon={(open) => {
              return (
                <IconButton
                  iconClassName="fill-textColor-tertiary"
                  size="none"
                >
                  {open ? <ClockIcon /> : <CloseCircleIcon />}
                </IconButton>
              );
            }}
            inputClassName="w-full"
            onClear={clearDateRange}
          />
        </div>

        <div className="flex">
          <Typography
            variant="p1"
            className="text-textColor-tertiary"
          >
            Total: &nbsp;
          </Typography>

          <Typography
            variant="p1"
            className="text-textColor-secondary"
          >
            {totalDays
              ? `${totalDays} ${totalDays === 1 ? 'day' : 'days'} (${totalDays * 24} hours)`
              : 'Dates are not selected'}
          </Typography>
        </div>
      </div>

      <div className="flex gap-[32px]">
        <Checkboxes
          className="w-1/2"
          label="Lunch Billable"
          options={isLunchAllowedOptions.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                label: option,
                onClick: () =>
                  handleChange({
                    target: {
                      name: 'isLunchAllowed',
                      value: option,
                    },
                  }),
              },
            ];
          }, [])}
          selectedValue={values.isLunchAllowed}
          isRequired
        />

        <Checkboxes
          label="Shift Type"
          className="hidden"
          options={shiftTypes.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                label: option,
                onClick: () =>
                  handleChange({
                    target: {
                      name: 'shiftType',
                      value: option,
                    },
                  }),
              },
            ];
          }, [])}
          selectedValue={values.shiftType}
          isRequired
        />
      </div>

      <div className="flex flex-col gap-1">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Default Shift Time
        </Typography>

        <Typography variant="label">
          Default times are applied when creating tickets, but they can be customized for each
          ticket
        </Typography>
      </div>

      <div className="flex justify-between items-center gap-4">
        <TimePickerInput
          label="Start Time"
          name="defaultScheduledStartTime"
          value={defaultScheduledStartTime}
          handleSelect={handleStartTimeSelect}
          onClear={(name) => setFieldValue(name, '')}
        />

        <TimePickerInput
          label="Shift Duration"
          name="defaultScheduledDuration"
          value={defaultScheduledDuration}
          handleSelect={handleDurationSelect}
          onClear={(name) => setFieldValue(name, '')}
        />

        <div className={clsx('w-full', endTimeEndsTomorrow && 'relative')}>
          <TimePickerInput
            value={endTimeValue}
            name="endTime"
            label="End Time"
            handleSelect={handleEndTimeSelect}
            onClear={() => setEndTimeValue('')}
          />

          {endTimeEndsTomorrow && (
            <EndsTomorrowIndicator
              className="absolute right-0 top-0"
              isTooltipAvailable={endTimeEndsTomorrow}
            />
          )}
        </div>
      </div>

      {/* <div className="flex justify-between gap-4">
        <TimePickerInput
          label="Yard Time"
          name="defaultScheduledTimeOnYard"
          value={defaultScheduledTimeOnYard}
          handleSelect={(name, value) => setFieldValue(name, value)}
          onClear={(name) => setFieldValue(name, '')}
        />

        <TimePickerInput
          label="Base Time"
          name="defaultScheduledBaseTime"
          value={defaultScheduledBaseTime}
          handleSelect={(name, value) => setFieldValue(name, value)}
          onClear={(name) => setFieldValue(name, '')}
        />
      </div> */}
    </div>
  );

  const renderModalContent = () => {
    if (isOwnerStepOpen) return ownerStepContent;

    if (isDetailsStepOpen) return detailsStepContent;

    return scheduleStepContent;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="h-full max-h-[880px] overflow-y-auto"
      removeScroll
    >
      <form
        className="flex flex-col justify-between gap-y-4 min-h-[848px] min-w-[930px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col justify-between gap-y-4">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            {`${type === 'create' ? 'Create new ' : 'Edit '} Job`}
          </Typography>

          {currentStep}

          <div className="flex flex-col gap-y-4">{renderModalContent()}</div>
        </div>

        {buttons}
      </form>

      {isAddNewContactModalOpen && owner && (
        <AddNewContact
          isAddNewContactModalOpen={isAddNewContactModalOpen}
          toggleContactModal={toggleContactModal}
          ownerID={owner.OwnerID}
          onCreate={refetch}
        />
      )}
    </Modal>
  );
};

export { CreateOrUpdateJobModal };
export type { CreateOrUpdateJobModalProps };
