import { FC } from 'react';

import { TextField } from 'src/shared/ui/textField';
import { Spinner } from 'src/shared/ui/spinner';
import { TagType } from 'src/shared/types';

interface IFilterPanelProps {
  modelName: TagType;
  modelLabel?: string;
  searchField: string;
  isFetching: boolean;
  handleChange: (value: string) => void;
}

const FilterPanel: FC<IFilterPanelProps> = ({
  searchField,
  isFetching,
  modelName,
  modelLabel,
  handleChange,
}) => {
  return (
    <div className="max-w-[500px] w-full">
      <TextField
        placeholder={`Search ${(modelLabel || modelName)?.toLowerCase() ?? ''}...`}
        name="name"
        value={searchField}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        className="relative"
        inputClassName="min-w-[300px]"
        endIcon={
          !!searchField && isFetching ? (
            <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-0">
              <Spinner size="xs" />
            </div>
          ) : undefined
        }
      />
    </div>
  );
};

export { FilterPanel };
