import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { TextField } from 'src/shared/ui/textField';
import { ActionReceiver } from 'src/shared/types/actions';
import { Checkbox } from 'src/shared/ui/checkbox';
import { useUpdateActionReceiverByIdMutation } from 'src/store/api';
import { showToastErrorMessage } from 'src/shared/utils';

import { addNewEmailReceiver } from '../../helpers/validation';

interface ChangeReceiverInfoModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onChangeInfo: () => void;
  receiverInfo: ActionReceiver;
}

const ChangeReceiverInfoModal: FC<ChangeReceiverInfoModalProps> = ({
  isOpen,
  closeModal,
  onChangeInfo,
  receiverInfo,
}) => {
  const toggleModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const [updateActionReceiver, { isLoading }] = useUpdateActionReceiverByIdMutation();

  const initialValues = useMemo(
    () => ({
      firstName: receiverInfo.firstName || '',
      lastName: receiverInfo.lastName || '',
      email: receiverInfo.email,
      isActive: receiverInfo.isActive || false,
    }),
    [receiverInfo],
  );

  const { values, handleChange, handleSubmit, errors } = useFormik({
    onSubmit: async (data: z.infer<typeof addNewEmailReceiver>) => {
      try {
        await updateActionReceiver({
          email: data.email,
          id: receiverInfo.id,
          firstName: data.firstName,
          lastName: data.lastName,
          isActive: data.isActive,
        });
        closeModal();
        onChangeInfo();
      } catch (error) {
        showToastErrorMessage('Error with updating mail receiver');
      }
    },
    validationSchema: toFormikValidationSchema(addNewEmailReceiver),
    initialValues,
    enableReinitialize: true,
  });

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[calc(100%-30px)] md:w-[646px] overflow-y-auto min-h-[300px]"
      removeScroll
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Edit Information
      </Typography>

      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-8 w-full max-h-[60vh] lg:max-h-[50vh] min-h-[368px] md:h-[616px] overflow-y-auto p-1">
          <TextField
            name="firstName"
            label="First Name"
            placeholder="First Name..."
            required
            isRequired
            value={values.firstName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'firstName',
                  value: value.target.value,
                },
              })
            }
            error={errors.firstName}
          />

          <TextField
            name="lastName"
            label="Last Name"
            placeholder="Last Name..."
            required
            isRequired
            value={values.lastName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'lastName',
                  value: value.target.value,
                },
              })
            }
            error={errors.lastName}
          />

          <TextField
            name="email"
            label="Email"
            placeholder="Email..."
            required
            isRequired
            value={values.email}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'email',
                  value: value.target.value,
                },
              })
            }
            error={errors.email}
          />

          <Checkbox
            name="isActive"
            checked={values.isActive}
            endLabel="Is User Active"
            onChange={handleChange}
            labelClassName="text-textColor-secondary text-[16px] heading-[16px] font-semibold"
          />
        </div>

        <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            size="lg"
            endIcon={<Icons.Outlined.Edit.CheckmarkIcon className="fill-white" />}
            autoFocus
            disabled={isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { ChangeReceiverInfoModal };
