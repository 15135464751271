import { ReactNode, FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { DEFAULT_TEXT } from './helpers/constants';

type TypographyAccessDeniedProps = TypographyProps & {
  children?: ReactNode;
};

export const TypographyAccessDenied: FC<TypographyAccessDeniedProps> = ({
  children,
  ...typographyProps
}) => {
  return (
    <Typography
      variant="body1"
      color="textSecondary"
      textAlign="center"
      {...typographyProps}
    >
      {children || DEFAULT_TEXT.accessDenied}
    </Typography>
  );
};
