import {
  useState,
  FC,
  useMemo,
  Dispatch,
  SetStateAction,
  useCallback,
  memo,
  CSSProperties,
} from 'react';
import { useSelector } from 'react-redux';
import { Box, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {
  DataGrid,
  GridRowId,
  GridColDef,
  GridRowModes,
  GridRowModel,
  GridRowParams,
  GridRowModesModel,
  GridEventListener,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridPaginationModel,
  GridSortModel,
  GridCallbackDetails,
} from '@mui/x-data-grid';
import { useSearchParams, useOutletContext } from 'react-router-dom';

import { useDeleteTableRowMutation, useEditTableRowMutation } from 'src/store/api';
import {
  TagType,
  MetadataMap,
  RowsMetadata,
  TypeNotation,
  RelationOption,
  DefaultTableValue,
  RelationOptionsMap,
  AdminPageContextType,
  TableManagerFieldValue,
  PaginationModelAdminPage,
  PaginationModelAdminPageDialog,
  Subjects,
} from 'src/shared/types';
import {
  showToastErrorMessage,
  dayjs,
  DATE_FORMAT,
  isApiError,
  hasTimeInFormat,
} from 'src/shared/utils';
import {
  selectCurrentUser,
  adminTableActions,
  TypeAdminPageModal,
  selectTableState,
} from 'src/store/slices';
import { useAppDispatch } from 'src/store';
import { usePermissions } from 'src/shared/hooks';

import { defaultErrorMessages } from '../../helpers';

import { getMultiSelectRenderer } from './MultiSelectEditComponent';

type CustomDataGridProps = {
  idField: string;
  total: number;
  isLoading: boolean;
  isFetching: boolean;
  isLoadingRelationOptions: boolean;
  isFetchingRelationOptions: boolean;
  intoDialog?: boolean;
  modelName: TagType;
  schema: TypeNotation[];
  tableData: Record<string, TableManagerFieldValue>[];
  paginationModel: PaginationModelAdminPage | PaginationModelAdminPageDialog;
  relationOptions: RelationOptionsMap;
  fieldsMetadata: MetadataMap;
  rowsMetadata?: RowsMetadata;
  position?: CSSProperties['position'];
  setPaginationModel?: Dispatch<SetStateAction<PaginationModelAdminPage>>;
  setPaginationDialogModel?: Dispatch<SetStateAction<PaginationModelAdminPageDialog>>;
  setSelectedRow: Dispatch<SetStateAction<Record<string, TableManagerFieldValue> | null>>;
  setIsCreateOrUpdateTableRowModalOpen: Dispatch<SetStateAction<boolean>>;
  onColumnOrderChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
};

const CustomDataGrid: FC<CustomDataGridProps> = ({
  total,
  schema,
  idField,
  tableData,
  modelName,
  isLoading,
  isFetching,
  fieldsMetadata,
  rowsMetadata,
  paginationModel,
  relationOptions,
  isLoadingRelationOptions,
  isFetchingRelationOptions,
  position = 'absolute',
  intoDialog = false,
  setPaginationModel,
  setPaginationDialogModel,
  onColumnOrderChange,
}) => {
  const { can } = usePermissions(modelName as Subjects);
  const dispatch = useAppDispatch();
  const { modelsOptions } = useOutletContext<AdminPageContextType>();
  const user = useSelector(selectCurrentUser);
  const [deleteTableRow, { isLoading: isLoadingDelete }] = useDeleteTableRowMutation();
  const [editTableRow, { isLoading: isLoadingEdit }] = useEditTableRowMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const { typeModal } = useSelector(selectTableState);

  const arrayColumns = useMemo(() => {
    return schema.filter((el) => el.type === 'array').map((el) => el.field);
  }, [schema]);

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((prevModel) => ({
        ...prevModel,
        [id]: { mode: GridRowModes.Edit },
      }));
    },
    [],
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((prevModel) => ({
        ...prevModel,
        [id]: { mode: GridRowModes.View },
      }));
    },
    [],
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => async () => {
      if (!can.delete) {
        showToastErrorMessage(defaultErrorMessages.delete);
        return;
      }

      try {
        await deleteTableRow({
          id: String(id),
          apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
          modelName,
          invalidatesTags: fieldsMetadata?.invalidatesTags,
        }).unwrap();
      } catch (error) {
        let errorMsg;

        if (isApiError(error)) {
          errorMsg = error.data.message;
        } else {
          errorMsg = defaultErrorMessages.delete;
        }

        showToastErrorMessage(errorMsg);
      }
    },
    [deleteTableRow, modelsOptions, modelName, fieldsMetadata?.invalidatesTags, can.delete],
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((prevModel) => ({
        ...prevModel,
        [id]: {
          mode: GridRowModes.View,
          ignoreModifications: true,
        },
      }));
    },
    [],
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  }, []);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      try {
        const updatedRow = Object.fromEntries(
          Object.entries(newRow).map(([key, value]) => {
            const oldValue = oldRow[key];
            return [key, value === oldValue ? undefined : value];
          }),
        ) as Partial<GridRowModel>;

        const dateFields = schema.filter(({ type }) => type === 'date').map(({ field }) => field);

        const preparedRow = Object.fromEntries(
          Object.entries(updatedRow).map(([key, value]) => {
            if (
              fieldsMetadata?.update &&
              key in fieldsMetadata.update &&
              !fieldsMetadata?.update?.[key]?.readonly &&
              fieldsMetadata.update?.[key]?.defaultValue === DefaultTableValue.email &&
              user?.email
            ) {
              return [key, user.email];
            }

            if (dateFields.includes(key)) {
              if (value) {
                return [key, dayjs(value).toISOString()];
              }
              return [key, undefined];
            }

            if (arrayColumns.includes(key) && typeof value === 'string') {
              return [key, value.split(',').map((el) => el.trim())];
            }

            return [key, value];
          }),
        );

        await editTableRow({
          id: String(newRow[idField]),
          apiEndpoint: modelsOptions?.[modelName].apiEndpoint,
          modelName,
          invalidatesTags: fieldsMetadata?.invalidatesTags,
          body: preparedRow,
        }).unwrap();

        return newRow;
      } catch (error) {
        let errorMsg;

        if (isApiError(error)) {
          errorMsg = error.data.message;
        } else {
          errorMsg = defaultErrorMessages.update;
        }

        showToastErrorMessage(errorMsg);

        return oldRow;
      }
    },
    [arrayColumns, editTableRow, idField, modelName, modelsOptions, schema, fieldsMetadata, user],
  );

  const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  const getIsCellEditable = (row: { id: string | number }) => {
    if (!can.update) {
      return false;
    }
    return !rowsMetadata?.[row.id]?.isEditingDisabled;
  };

  const preparedSchema: GridColDef[] = useMemo(() => {
    return schema
      .filter((item) => {
        const gridMetadata = fieldsMetadata?.grid;

        const isHidden = gridMetadata
          ? item.field in gridMetadata && gridMetadata[item.field]?.hidden
          : false;

        return !isHidden;
      })
      .map((item) => {
        const headerName = fieldsMetadata?.headerNames?.[item.field]?.name ?? item.field;

        const isEditable = item.isEditable && !fieldsMetadata.grid?.[item.field]?.readonly;
        const isSortable = fieldsMetadata?.sortableFields
          ? fieldsMetadata?.sortableFields?.includes(item.field)
          : true;

        const commonProps = {
          field: item.field,
          editable: isEditable,
          minWidth: 100,
          flex: 1,
          headerName,
          hide: true,
          sortable: isSortable,
        };

        if (item.field in relationOptions && item.type === 'array') {
          const renderOptions =
            item.type === 'array'
              ? {
                  renderEditCell: getMultiSelectRenderer(relationOptions[item.field]),
                }
              : {};

          return {
            ...commonProps,
            ...renderOptions,
            type: 'singleSelect',
            valueOptions: relationOptions[item.field],
            valueFormatter: (values: string[] | string) => {
              return Array.isArray(values)
                ? values
                    .map(
                      (value) =>
                        relationOptions[item.field].find(
                          ({ value: optionValue }) => optionValue === value,
                        )?.label,
                    )
                    .join(', ')
                : values;
            },
            getOptionLabel: ({ label }: RelationOption) => label,
            getOptionValue: ({ value }: RelationOption) => value,
          };
        }
        if (item.field in relationOptions) {
          return {
            ...commonProps,

            type: 'singleSelect',
            valueOptions: relationOptions[item.field],

            getOptionLabel: ({ label }: RelationOption) => label,
            getOptionValue: ({ value }: RelationOption) => value,
          };
        }

        if (item.type === 'enum') {
          return {
            ...commonProps,
            type: 'singleSelect',
            valueOptions: item.values,
          };
        }

        if (item.type === 'date') {
          const dateFormat =
            fieldsMetadata?.fieldsConfigurations?.[item.field]?.dateFormat ||
            DATE_FORMAT.DEFAULT_ADMIN_PAGE;

          const hasTime = hasTimeInFormat(dateFormat);

          return {
            ...commonProps,
            type: hasTime ? 'dateTime' : 'date',
            valueFormatter: (params) => {
              const dateValue = dayjs(params);

              return dateValue.isValid() ? dateValue.local().format(dateFormat) : '-';
            },
          };
        }

        const formattedType = item.type === 'array' ? 'string' : item.type;

        return {
          ...commonProps,
          type: formattedType,
        };
      });
  }, [relationOptions, schema, fieldsMetadata]);

  const handleDetailsModalClick = useCallback(
    (id: GridRowId) => {
      dispatch(
        adminTableActions.selectRow({
          id,
          typeModal: TypeAdminPageModal.Details,
        }),
      );
    },
    [dispatch],
  );

  const handleUpdateModalClick = useCallback(
    (id: GridRowId) => () => {
      dispatch(
        adminTableActions.selectRow({
          id,
          typeModal: TypeAdminPageModal.Update,
        }),
      );
    },
    [dispatch],
  );

  const isLoadingOverall =
    isLoadingDelete ||
    isLoadingEdit ||
    isLoading ||
    isFetching ||
    isLoadingRelationOptions ||
    isFetchingRelationOptions;

  const isActionButtonsDisabled = intoDialog && typeModal === TypeAdminPageModal.Details;

  const columns: GridColDef[] = useMemo(() => {
    return [
      ...preparedSchema,

      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 200,
        cellClassName: 'actions',

        getActions: ({ id }: GridRowParams) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          const rowMetadata = rowsMetadata?.[id];

          const detailsBtn = (
            <Tooltip
              title="Details"
              key={`details-${id}`}
            >
              <span>
                <GridActionsCellItem
                  icon={<InfoOutlinedIcon />}
                  label="Details"
                  className="textPrimary"
                  onClick={() => handleDetailsModalClick(id)}
                  color="inherit"
                  disabled={isLoadingOverall}
                />
              </span>
            </Tooltip>
          );

          const deleteBtn = can.delete && (
            <Tooltip
              title="Delete"
              key={`delete-${id}`}
            >
              <span>
                <GridActionsCellItem
                  key={`delete-${id}`}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
                  disabled={
                    isLoadingOverall || rowMetadata?.isDeletionDisabled || isActionButtonsDisabled
                  }
                />
              </span>
            </Tooltip>
          );

          const editModalBtn = can.update && (
            <Tooltip
              title="Edit modal"
              key={`edit-modal-${id}`}
            >
              <span>
                <GridActionsCellItem
                  key={`edit-${id}`}
                  icon={<AppRegistrationIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleUpdateModalClick(id)}
                  color="inherit"
                  disabled={
                    isLoadingOverall || rowMetadata?.isEditingDisabled || isActionButtonsDisabled
                  }
                />
              </span>
            </Tooltip>
          );
          const editBtn = can.update && (
            <Tooltip
              title="Edit row"
              key={`edit-${id}`}
            >
              <span>
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                  disabled={
                    isLoadingOverall || rowMetadata?.isEditingDisabled || isActionButtonsDisabled
                  }
                />
              </span>
            </Tooltip>
          );

          if (isInEditMode) {
            return [
              detailsBtn,
              <Tooltip
                title="Save"
                key={`save-${id}`}
              >
                <span>
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    sx={{
                      color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                    disabled={isLoadingOverall || isActionButtonsDisabled}
                  />
                </span>
              </Tooltip>,
              <Tooltip
                title="Cancel"
                key={`cancel-${id}`}
              >
                <span>
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                    disabled={isLoadingOverall}
                  />
                </span>
              </Tooltip>,
            ];
          }

          return [detailsBtn, editModalBtn, editBtn, deleteBtn].filter(Boolean);
        },
      },
    ];
  }, [
    preparedSchema,
    rowModesModel,
    isLoadingOverall,
    handleCancelClick,
    handleDeleteClick,
    handleEditClick,
    handleSaveClick,
    handleDetailsModalClick,
    handleUpdateModalClick,
    rowsMetadata,
    isActionButtonsDisabled,
    can.delete,
    can.update,
  ]);

  const paginationModelData: GridPaginationModel | undefined =
    !intoDialog && 'page' in paginationModel && 'pageSize' in paginationModel
      ? (paginationModel as GridPaginationModel)
      : undefined;
  const paginationModelDialogData: GridPaginationModel | undefined =
    intoDialog && 'dialogPage' in paginationModel && 'dialogPageSize' in paginationModel
      ? ({
          page: paginationModel.dialogPage,
          pageSize: paginationModel.dialogPageSize,
        } as GridPaginationModel)
      : undefined;

  return (
    <Box
      sx={{
        flex: 1,
        position: 'relative',
        height: '100%',
        gap: '2rem',
      }}
    >
      <Box
        sx={{
          position,
          inset: 0,
          height: '580px',
        }}
      >
        <DataGrid
          rows={tableData}
          columns={columns}
          loading={isLoadingOverall}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            borderRadius: 0,
          }}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={paginationModelData ?? paginationModelDialogData}
          onPaginationModelChange={(newValue) => {
            const newSearchParams = new URLSearchParams(searchParams.toString());

            if (setPaginationModel) {
              setPaginationModel(newValue);

              newSearchParams.set('page', newValue.page.toString());
              newSearchParams.set('pageSize', newValue.pageSize.toString());

              const searchValue = searchParams.get('search');
              if (searchValue) {
                newSearchParams.set('search', searchValue);
              }
            }

            if (setPaginationDialogModel) {
              setPaginationDialogModel({
                dialogPage: newValue.page,
                dialogPageSize: newValue.pageSize,
              });

              newSearchParams.set('dialogPage', newValue.page.toString());
              newSearchParams.set('dialogPageSize', newValue.pageSize.toString());

              const dialogSearchValue = searchParams.get('dialogSearch');
              if (dialogSearchValue) {
                newSearchParams.set('dialogSearch', dialogSearchValue);
              }
            }

            setSearchParams(newSearchParams);
          }}
          getRowId={(row) => row[idField]}
          paginationMode="server"
          rowCount={total}
          disableColumnFilter={isLoadingOverall}
          disableColumnSorting={isLoadingOverall}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          isCellEditable={getIsCellEditable}
          onSortModelChange={onColumnOrderChange}
          sortingMode={onColumnOrderChange ? 'server' : 'client'}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'linear-progress',
            },
          }}
        />
      </Box>
    </Box>
  );
};

const MemoizedCustomDataGrid = memo(CustomDataGrid);

export { MemoizedCustomDataGrid as CustomDataGrid };
