import { api } from 'src/store/api/api';
import { Permission } from 'src/shared/types';

const inspectorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPermissionsByRole: builder.query<Permission[], ''>({
      query: () => ({
        url: '/permissions/by-role',
        method: 'GET',
      }),
      providesTags: ['Permissions'],
      transformResponse: (response: { data: Permission[] }) => response.data,
    }),
  }),
});

export const { useGetPermissionsByRoleQuery } = inspectorApi;
