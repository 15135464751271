import { Role } from 'src/shared/types';

type RoleAccessParams = {
  roles: Role[];
  userRole: Role;
  forbiddenRoles: Role[];
};

const checkUserRoleAccess = ({ userRole, roles, forbiddenRoles }: RoleAccessParams): boolean => {
  const allowedRoles = roles.filter((role) => !forbiddenRoles.includes(role));
  return allowedRoles.includes(userRole);
};

export { checkUserRoleAccess };
