import { FC, useCallback, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/outlined/misc/clock.svg';
import { Icons } from 'src/assets/icons';
import { Filter, Role } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import { Filters } from 'src/shared/ui/filters';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { IconButton } from 'src/shared/ui/iconButton';
import { Tag } from 'src/shared/ui/tag';
import { TextField } from 'src/shared/ui/textField';
import { Typography } from 'src/shared/ui/typography';
import { Datepicker } from 'src/shared/ui/datepicker';
import { DateRangeType } from 'src/shared/ui/datepicker/types';
import { selectCurrentUser } from 'src/store/slices';
import { ADMIN_ROLES } from 'src/shared/constants';

import { AddNewActionModal } from '../addNewActionModal';

interface ActionHeaderBlockProps {
  onExportToExcel: () => void;
  appliedFiltersAmount: number;
  filters: Filter[];
  search: string;
  setSearch: (value: string) => void;
  onActionAdd: () => void;
  setDateRangeFilter: (value: DateRangeType | null) => void;
}

const ActionHeaderBlock: FC<ActionHeaderBlockProps> = ({
  onExportToExcel,
  appliedFiltersAmount,
  filters,
  search,
  setSearch,
  onActionAdd,
  setDateRangeFilter,
}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isNewActionAdd, setIsNewActionAdd] = useState(false);
  const toggleFilterMenu = () => setIsFilterMenuOpen((prev) => !prev);

  const [dateRange, setDateRange] = useState<DateRangeType | null>(null);

  const handleChangeDateRange = (val: DateRangeType | null) => setDateRange(val);

  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const isAdmin = useMemo(
    () => ADMIN_ROLES.includes(user?.ProviderRoleMatrix?.userRole as Role),
    [user],
  );

  const renderFilters = useMemo(() => {
    return filters.map((filter) => (
      <FilterSelect
        key={`ActivityFilter-${filter.label}`}
        options={filter.options.map((option) => ({
          label: option,
          value: option,
        }))}
        label={filter.label}
        placeholder={`Filter by ${filter.label}`}
        id={filter.label}
        type="board"
      />
    ));
  }, [filters]);

  const handleAddAction = () => {
    setIsNewActionAdd(true);
  };

  const getIcon = useCallback((open: boolean) => {
    return (
      <IconButton
        iconClassName="fill-textColor-tertiary"
        size="none"
      >
        {open ? <ClockIcon /> : <CloseCircleIcon />}
      </IconButton>
    );
  }, []);

  const clearDateRange = () => {
    handleChangeDateRange({
      endDate: null,
      startDate: null,
    });
  };
  const onClearAllClick =
    dateRange && dateRange.startDate
      ? () => {
          setDateRangeFilter({
            startDate: null,
            endDate: null,
          });
          setDateRange({
            startDate: null,
            endDate: null,
          });
        }
      : undefined;

  return (
    <>
      <div className="flex items-center gap-4 justify-between flex-wrap my-3">
        <div className="flex gap-2 flex-wrap items-center">
          <Typography variant="h2">Action Tracker</Typography>

          <Tooltip title="Add New Action">
            <Button
              variant="outlined"
              color="primary"
              className="!size-10 !min-w-10 !px-3 hidden"
              onClick={handleAddAction}
            >
              +
            </Button>
          </Tooltip>
        </div>

        <div className="flex items-center gap-4 flex-wrap">
          <div className="flex gap-2">
            <div className="relative">
              <IconButton
                size="none"
                iconSize="md"
                color="basic"
                className="h-[48px] w-[48px]"
                onClick={toggleFilterMenu}
              >
                <Icons.Outlined.Controls.FilterIcon />
              </IconButton>

              {!!appliedFiltersAmount && (
                <Tag
                  type="white"
                  className="absolute right-[-2px] top-0 !px-1"
                >
                  {appliedFiltersAmount}
                </Tag>
              )}
            </div>

            {isFilterMenuOpen && (
              <Filters
                title="Filters"
                isOpen={isFilterMenuOpen}
                closeMenu={setIsFilterMenuOpen}
                type="board"
                className="w-[calc(100%-30px)] right-[15px] md:w-auto md:right-[60px] top-[85px] md:min-w-[450px] md:max-w-[450px] z-[9999]"
                onApplyClick={() => setDateRangeFilter(dateRange)}
                onClearAll={onClearAllClick}
              >
                {renderFilters}

                <div>
                  <Typography
                    variant="p1"
                    fontWeight="semibold"
                    className="pb-2 text-textColor-secondary"
                  >
                    Date Range
                  </Typography>

                  <div className="relative">
                    <Datepicker
                      useRange={false}
                      placeholder="Select filter dates"
                      value={dateRange}
                      onChange={(value) => {
                        handleChangeDateRange(value);
                      }}
                      displayFormat="dddd, DD MMMM"
                      separator="-"
                      toggleClassName={clsx(
                        dateRange?.startDate && dateRange?.endDate
                          ? 'absolute right-[12px] top-[12px] bg-transparent'
                          : 'absolute left-[16px] top-[12px] bg-transparent',
                      )}
                      toggleIcon={getIcon}
                      inputClassName="w-full"
                      onlyCalendar
                    />

                    <Button
                      variant="ghost"
                      onClick={clearDateRange}
                      className={clsx(
                        'px-0 absolute right-0 top-1/2 -translate-y-1/2 !min-w-max',
                        !(dateRange && dateRange.startDate) && '-z-10',
                      )}
                      startIcon={
                        dateRange && dateRange.startDate ? (
                          <Icons.Filled.Edit.CloseIcon />
                        ) : (
                          <Icons.Filled.Misc.Calendar />
                        )
                      }
                    />
                  </div>
                </div>
              </Filters>
            )}
          </div>

          <Button
            size="lg"
            variant="filled"
            endIcon={<Icons.Filled.Files.DownloadIcon fill="#231F20" />}
            className="border-[#636874] flex !gap-3 !p-0"
            onClick={onExportToExcel}
          >
            Excel
          </Button>

          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-[360px]"
            inputClassName="bg-white"
            startIcon={
              <Icons.Filled.InternetCode.Search className="absolute top-[13px] left-[13px] fill-[#7F8EB4]" />
            }
            endIcon={
              search ? (
                <IconButton
                  size="md"
                  className="absolute right-1 top-1"
                  onClick={() => setSearch('')}
                >
                  <Icons.Outlined.Edit.CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                </IconButton>
              ) : undefined
            }
          />

          {isAdmin && (
            <Tooltip title="Settings">
              <IconButton
                size="none"
                iconSize="md"
                color="basic"
                className="h-[48px] w-[48px]"
                onClick={() => navigate('/actions/unclosed-findings/settings')}
              >
                <Icons.Filled.Security.SettingsIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>

      {isNewActionAdd && (
        <AddNewActionModal
          isOpen={isNewActionAdd}
          closeModal={() => setIsNewActionAdd(false)}
          onActionAdd={onActionAdd}
        />
      )}
    </>
  );
};

export { ActionHeaderBlock };
