import { ChangeEvent, forwardRef, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as ChevronUpIcon } from 'src/assets/icons/outlined/chevrons/chevron-up.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/outlined/edit/close-circle.svg';
import { TextField, type TextFieldProps } from 'src/shared/ui/textField';
import { DropDown, DropDownItem, DropDownProps } from 'src/shared/ui/dropDown';
import { noop } from 'src/shared/utils';

import { IconButton } from '../iconButton';

type SelectInputProps = TextFieldProps & {
  items: DropDownProps['items'];
  onClear?: (name: string) => void;
  onCustomValue?: (value: string) => void;
  clearFieldState?: () => void;
  hasAddNewItemButton?: {
    onClick: () => void;
    buttonTitle: string;
  };
};

const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(
  (
    {
      items,
      isRequired = false,
      onClear,
      onCustomValue = noop,
      clearFieldState,
      hasAddNewItemButton,
      ...props
    },
    ref,
  ) => {
    const [search, setSearch] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<string | null>(
      typeof props.value === 'string' ? props.value : null,
    );

    useEffect(() => {
      if (typeof props.value === 'string') {
        setSelectedValue(props.value);
        setSearch('');
      }
    }, [props.value]);

    const visibleItems = useMemo(
      () =>
        items.filter((item) => {
          if (typeof item.value !== 'string') {
            return false;
          }
          if (selectedValue) {
            return true;
          }
          const formattedSearch = search.toLowerCase();
          return item.value.toLowerCase().includes(formattedSearch);
        }),
      [selectedValue, search, items],
    );

    const clearSearch = () => {
      if (clearFieldState) {
        clearFieldState();
      }
      setSearch('');
      setSelectedValue(null);

      if (onClear && props.name) {
        onClear(props.name);
      }
    };

    const handleSelectItem = (item: DropDownItem) => {
      if (typeof item.value === 'string') {
        setSelectedValue(item.labelName || item.value);
      }
      setSearch('');
    };

    const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedValue(null);

      const { value } = event.target;

      setSearch(value);
      onCustomValue(value);
    };

    const fieldValue = selectedValue || search;

    return (
      <DropDown
        options={{ placement: 'bottom-start' }}
        items={visibleItems}
        config={{
          sameWidth: true,
          itemsElementClassName: (isOpen) =>
            clsx(
              'rounded-3xl w-full bg-[#F3F6FA] border border-outlineColor-input-border py-6',
              !isOpen && 'p-0 border-0',
            ),
          onItemClick: handleSelectItem,
          setSearchOnSelect: false,
          focusLastElement: false,
        }}
        renderElement={(isOpen, toggleDropDown) => (
          <TextField
            autoComplete="off"
            endIcon={
              <>
                {!props.disabled && fieldValue ? (
                  <IconButton
                    size="md"
                    className="absolute right-[40px] top-[4px]"
                    onClick={clearSearch}
                    iconClassName="pointer-events-none"
                  >
                    <CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                  </IconButton>
                ) : undefined}

                {!props.disabled ? (
                  <button
                    type="button"
                    onClick={toggleDropDown}
                  >
                    <ChevronUpIcon
                      className={`fill-[#2E3A59] absolute right-[12px] top-[12px] ${
                        isOpen ? 'rotate-0' : 'rotate-180'
                      }`}
                    />
                  </button>
                ) : undefined}
              </>
            }
            ref={ref}
            {...props}
            isRequired={isRequired}
            value={fieldValue}
            onChange={handleSearchInput}
          />
        )}
        hasAddNewItemButton={hasAddNewItemButton}
      />
    );
  },
);

SelectInput.displayName = 'SelectInput';

export { SelectInput };
export type { SelectInputProps };
