import { AbilityTuple, MongoAbility, MongoQuery } from '@casl/ability';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';

type Subjects =
  | 'all'
  | 'projects'
  | 'inspectors'
  | 'assignments'
  | 'visits'
  | 'clients'
  | 'client-contacts'
  | 'survey'
  | 'survey-answer';

type AppAbility = MongoAbility<[Actions, Subjects] | AbilityTuple, MongoQuery>;

type CanFunction = AppAbility['can'];
type CannotFunction = AppAbility['cannot'];

enum Action {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

enum PermissionsSubject {
  All = 'all',
  Project = 'projects',
  Inspector = 'inspectors',
  Assignment = 'assignments',
  Visit = 'visits',
  Client = 'clients',
  ClientContact = 'client-contacts',
  Survey = 'survey',
  SurveyAnswer = 'survey-answer',
}

export type { Actions, Subjects, AppAbility, CanFunction, CannotFunction };
export { Action, PermissionsSubject };
