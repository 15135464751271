import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { Permission } from 'src/shared/types';

const createAbilityFromPermissions = (permissions: Permission[]) => {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  permissions.forEach(({ action, subject }) => {
    can(action, subject);
  });

  const ability = build();

  ability.can = ability.can.bind(ability);
  ability.cannot = ability.cannot.bind(ability);

  return ability;
};

export { createAbilityFromPermissions };
