import { FC, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'src/shared/ui/card';
import { PeopleList } from 'src/shared/ui/peopleList';
import { clsx, formatDate } from 'src/shared/utils';
import {
  useGetPeopleQuery,
  useGetTicketsQuery,
  useGetSidebarFiltersQuery,
  useGetMostUsedJobTitlesQuery,
  useGetProviderDivisionsQuery,
} from 'src/store/api';
import { RootState, useAppDispatch } from 'src/store';
import { modalConfigActions, filtersActions, selectConfig } from 'src/store/slices';
import { useGetProviderBranchesQuery } from 'src/store/api/providerBranches';
import { PersonEntity } from 'src/shared/types';
import { Filters } from 'src/shared/ui/filters';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { Button } from 'src/shared/ui/button';
import { useDebounce } from 'src/shared/hooks/useDebounce';

import { CardTitle } from './cardTitle';
import { SearchSideBarInput } from './searchSideBarInput';

const filterMenuType = 'sidebar';

const availablePeopleFilters = ['Provider Branch', 'Division'];

const SidebarPeopleCard: FC = () => {
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const searchQuery = useDebounce(search, 300);

  const config = useSelector(selectConfig);
  const selectedBranchFilters = useSelector(
    (state: RootState) => state.filters.sidebar.selectedFilters,
  ).filter((el) => el.id === 'Provider Branch');
  const filterAssignedForDate = useSelector(
    (state: RootState) => state.schedulerBoardSettings.filterAssignedForDate,
  );

  const { data: people = [] } = useGetPeopleQuery({
    filters: config.sidebarFilters,
    pagination: {
      search: searchQuery,
    },
  });
  const { data: mostUsedJobTitles = [] } = useGetMostUsedJobTitlesQuery('');
  const { data: filters = [] } = useGetSidebarFiltersQuery(config.sidebarFilters);
  const { data = { tickets: [] } } = useGetTicketsQuery({});
  const { data: providerBranches = [] } = useGetProviderBranchesQuery('');
  const { data: providerDivisions = [] } = useGetProviderDivisionsQuery('');

  const [isOpen, setIsOpen] = useState(false);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<string[]>(['Field Only']);

  const { tickets } = data;

  const { equipmentTypeSidebar, ...formattedPeopleFilters } = config.sidebarFilters;

  const appliedFiltersAmount = Object.values(formattedPeopleFilters).filter(
    (filter) => filter.length > 0,
  ).length;

  const ticketsForToday = tickets.filter(
    (ticket) => formatDate(ticket.startDate) === formatDate(filterAssignedForDate),
  );

  const assignedPeople = filterAssignedForDate
    ? ticketsForToday
        .flatMap((ticket) => [
          ...ticket.employees.map((person) => person.id),
          ...ticket.supervisors.map((person) => person.id),
          ticket?.crewLeader?.ProviderPersonnel?.id || '',
        ])
        .filter((el) => el)
    : [];

  const filterPerson = (person: PersonEntity) => {
    if (selectedQuickFilters.find((el) => el === 'All')) {
      return true;
    }

    const hasFieldOnlyFilter = selectedQuickFilters.find((el) => el === 'Field Only');

    if (hasFieldOnlyFilter && selectedQuickFilters.length === 1) {
      return person.typicalWorkClassification?.startsWith('Field');
    }

    if (hasFieldOnlyFilter && selectedQuickFilters.length > 1) {
      const filteredPeopleFilters = selectedQuickFilters.filter((el) => el !== 'Field Only');

      return (
        person.typicalWorkClassification?.startsWith('Field') &&
        filteredPeopleFilters.find((el) => el === person?.jobTitle)
      );
    }

    if (selectedQuickFilters.find((el) => el === person?.jobTitle)) {
      return true;
    }

    return false;
  };

  const visiblePeople = people
    .filter(filterPerson)
    .sort((a, b) => `${a.firstName} ${a.lastName}`.length - `${b.firstName} ${b.lastName}`.length);

  const getFilteredOptions = (filter: { options: string[] }) => {
    const filteredOptions = filter.options.map((option) => ({
      label: option,
      value: option,
    }));

    return filteredOptions;
  };

  const getFilteredDivisionOptions = () => {
    const selectedBranchOptionsIds = providerBranches.filter((option) =>
      selectedBranchFilters.some((selectedOption) => selectedOption.value === option.BranchName),
    );

    const visibleDivisions = providerDivisions.filter((option) =>
      selectedBranchOptionsIds.some(
        (selectedBranchOption) => selectedBranchOption.BranchID === option.ProviderBranchID,
      ),
    );

    const filteredOptions = visibleDivisions.map((option) => ({
      label: option.ProviderDivisionName,
      value: option.ProviderDivisionName,
    }));

    return filteredOptions;
  };

  const toggleQuickFilter = (value: string) => {
    if (value === 'All') {
      setSelectedQuickFilters(['All']);
      return;
    }

    const newQuickFilters = selectedQuickFilters.includes(value)
      ? selectedQuickFilters.filter((el) => el !== value && el !== 'All')
      : [...selectedQuickFilters.filter((el) => el !== 'All'), value];

    if (newQuickFilters.length === 0) {
      newQuickFilters.push('All');
    }

    setSelectedQuickFilters(newQuickFilters);
  };

  const openFilterMenu = (e: MouseEvent<HTMLButtonElement>) => {
    if (isOpen) {
      e.stopPropagation();
    }

    setIsFilterMenuOpen((isOpen) => !isOpen);

    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const getFilterButton = (label: string, name: string) => {
    return (
      <Button
        size="sm"
        variant={selectedQuickFilters.includes(name) ? 'filled' : 'outlined'}
        color={selectedQuickFilters.includes(name) ? 'primary' : 'basic'}
        className={clsx(
          '!rounded-[25px] w-[103px] !h-[27px]',
          selectedQuickFilters.includes(name) && 'border border-solid border-transparent',
        )}
        onClick={() => toggleQuickFilter(name)}
      >
        {label}
      </Button>
    );
  };

  const getFilters = () => {
    const availableFilters = filters.filter((filter) =>
      availablePeopleFilters.includes(filter.label),
    );

    return availableFilters.map((filter, index) => (
      <FilterSelect
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        options={
          filter.label === 'Division' ? getFilteredDivisionOptions() : getFilteredOptions(filter)
        }
        label={filter.label}
        placeholder={`Filter by ${filter.label}`}
        id={`${filter.label} Sidebar`}
        type="sidebar"
        disabled={filter.label === 'Division' && !selectedBranchFilters.length}
        isSingle={filter.label === 'Provider Branch'}
        onRemoveAllClick={
          filter.label === 'Provider Branch'
            ? () => {
                dispatch(
                  filtersActions.removeFiltersById({
                    type: 'sidebar',
                    id: 'Division',
                  }),
                );
              }
            : undefined
        }
        onOptionSelect={
          filter.label === 'Provider Branch'
            ? () => {
                dispatch(
                  filtersActions.removeFiltersById({
                    type: 'sidebar',
                    id: 'Division',
                  }),
                );
              }
            : undefined
        }
      />
    ));
  };

  return (
    <Card
      handleAccordionChange={(isOpen) => setIsOpen(!isOpen)}
      open={isOpen}
      isAccordion
      iconSize="none"
      accordionTitle={
        <CardTitle
          title="People"
          count={visiblePeople.length}
          isFilterMenuOpen={isFilterMenuOpen}
          onOpenFilterMenu={openFilterMenu}
          appliedFiltersAmount={appliedFiltersAmount}
        />
      }
      className="relative min-h-[60px]"
    >
      <div className="flex flex-wrap gap-2 mb-6">
        {getFilterButton('All people', 'All')}

        {getFilterButton('Field Only', 'Field Only')}

        {mostUsedJobTitles.map((el) => (
          <Button
            key={el.JobTitle}
            size="sm"
            className={clsx(
              '!rounded-[25px] min-w-[95px] !h-[27px]',
              selectedQuickFilters.includes(el.JobTitle) &&
                'border border-solid border-transparent',
            )}
            variant={selectedQuickFilters.includes(el.JobTitle) ? 'filled' : 'outlined'}
            color={selectedQuickFilters.includes(el.JobTitle) ? 'primary' : 'basic'}
            onClick={() => toggleQuickFilter(el.JobTitle)}
          >
            {el.JobTitle}
          </Button>
        ))}
      </div>

      {isFilterMenuOpen && (
        <Filters
          title="Filters"
          closeMenu={setIsFilterMenuOpen}
          type={filterMenuType}
          className="top-[47px] left-[15px] min-w-[347px] max-w-[347px]"
          isOpen={isFilterMenuOpen}
        >
          {getFilters()}
        </Filters>
      )}

      <SearchSideBarInput
        value={search}
        type="people"
        onChange={(e) => setSearch(e.target.value)}
        onReset={() => setSearch('')}
      />

      <PeopleList
        isDropDisabled
        employees={assignedPeople}
        people={visiblePeople}
      />
    </Card>
  );
};

export { SidebarPeopleCard };
