import { FC } from 'react';
import { Autocomplete, MenuItem, AutocompleteProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { RelationOption } from 'src/shared/types';

import { InputField } from '../inputField';

type Item = RelationOption | string;

type MultiSelectAutocompleteProps<T> = {
  items: T[];
  selectedItems: T[];
  label?: string;
  handleChange?: (newValue: T[]) => void;
  readOnly?: boolean;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
} & Omit<AutocompleteProps<T, true, false, false, 'div'>, 'renderInput' | 'options'>;

const MultiSelectAutocomplete = <T extends Item>({
  items,
  selectedItems,
  handleChange,
  label,
  readOnly = false,
  required = false,
  error = false,
  disabled = false,
}: MultiSelectAutocompleteProps<T>) => {
  const getItemLabel = (item: Item) => (typeof item === 'string' ? item : item.label);
  const getItemValue = (item: Item) => (typeof item === 'string' ? item : item.value);

  return (
    <Autocomplete
      readOnly={readOnly}
      multiple
      options={items}
      getOptionLabel={(option) => getItemLabel(option)}
      isOptionEqualToValue={(option, value) => getItemValue(option) === getItemValue(value)}
      disableCloseOnSelect
      value={selectedItems}
      onChange={(_event, newValue) => {
        if (handleChange) {
          handleChange(newValue);
        }
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          error={error}
          required={required}
          variant="outlined"
          label={label || 'Select value'}
          placeholder={label || 'Select value'}
          readOnly={readOnly}
        />
      )}
      sx={{
        '& .MuiOutlinedInput-input': {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
      }}
      disabled={disabled}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={getItemValue(option)}
          value={getItemValue(option)}
          sx={{ justifyContent: 'space-between' }}
        >
          {getItemLabel(option)}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />
  );
};

export { MultiSelectAutocomplete };
