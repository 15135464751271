import { Action, ActionById, ActionDocument, ActionDocumentation } from 'src/shared/types/actions';

import { ActionStatus } from './types';

export const getFileName = (fileName: string, actionId: string) => {
  const filePaths = fileName.split('/');

  if (filePaths[0] !== actionId || !filePaths[1]) {
    return fileName;
  }

  const slashIndex = fileName.indexOf('/');
  const dateAndName = fileName.slice(slashIndex + 1);
  const dashIndex = dateAndName.indexOf('-');
  return dateAndName.slice(dashIndex + 1);
};

export const getActionDocuments = (actionDocumentation: ActionDocumentation[]) => {
  return actionDocumentation.length
    ? actionDocumentation.reduce((acc, files) => {
        return files.documents.length ? [...acc, ...files.documents] : acc;
      }, [] as ActionDocument[])
    : [];
};

export const getIsRequiredNotes = (currentStatus: string, actionData?: ActionById) => {
  return (
    !!actionData?.action.actualState?.status &&
    actionData.action.actualState.status !== ActionStatus.Closed &&
    currentStatus === ActionStatus.Closed
  );
};

export const getUnitNameById = (action?: Action) => {
  if (!action || !action.refinery) {
    return '';
  }

  if (action.refinery.OwnerUnits.length) {
    const name = action.refinery.OwnerUnits.find((unit) => unit.UnitID === action.unitId)?.UnitName;

    if (name) {
      return name;
    }
  }

  const unitsInArea = action.refinery.OwnerArea.find((area) =>
    area.OwnerUnits.find((unit) => unit.UnitID === action.unitId),
  )?.OwnerUnits;

  if (unitsInArea) {
    const unitNameInArea = unitsInArea.find((unit) => unit.UnitID === action.unitId)?.UnitName;
    if (unitNameInArea) {
      return unitNameInArea;
    }
  }

  return '';
};
